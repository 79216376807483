.footer {
	margin-top: 100px;
	color: #9CA2AB;
	padding-bottom: 80px;
	&__descriptor {
		display: inline-block;
		max-width: 178px;
		line-height: 140%;
	}
	&__row {
		display: inline-flex;
		vertical-align: top;
		padding-left: 5px;
		flex-wrap: wrap;
		width: calc(100% - 188px);
		justify-content: space-between;
		align-items: flex-start;
	}
	&__item {
		width: calc(100% / 3 - 120px);
		margin-left: 120px;
		&._email {
			padding-top: 4px;
			text-align: right;
		}
		&._phone {
			padding-top: 4px;
			padding-left: 38px;
		}
		&._developer {
			text-align: right;
		}
	}
	&__social {
		display: flex;
		align-items: center;
		&-title {
			margin-right: 25px;
			font-size: 14px;
		}
	}
	&__phone {
		position: relative;
		color: @main;
		font-weight: 700;
		&:after {
			position: absolute;
			content: '';
			top: -4px;
			left: -38px;
			width: 28px;
			height: 28px;
			border-radius: 28px;
			background: url(../img/phone.svg) center no-repeat, linear-gradient(180deg, #4BBEFF 0%, #42A6DF 100%);
		}
	}
	&__email {
		.link();
		font-size: 14px;
		&:after {
			position: absolute;
			content: '';
			top: -4px;
			left: -38px;
			width: 28px;
			height: 28px;
			border-radius: 28px;
			background: url(../img/mail.svg) center no-repeat, linear-gradient(#4BBEFF 0%, #42A6DF 100%);
			background-size: 12px;
		}
	}
	&__policy, &__reserved, &__developer {
		font-size: 12px;
		display: inline-block;
		margin-top: 20px;
	}
	&__policy {
		color: inherit;
		position: relative;
		transition: .4s;
		&:hover {
			color: @link;
		}
		&:before {
			position: absolute;
			content: '';
			bottom: -4px;
			left: 0;
			right: 0;
			height: 1px;
			background: #9CA2AB;
			opacity: .2;
		}
	}
	&__developer {
		text-align: right;
		&-link {
			.link();
		}
	}
}