.documents {
	position: relative;
	margin-top: 120px;
	padding-top: 140px;
	color: #fff;
	&__row {
		position: relative;
		max-width: 422px;
		z-index: 2;
	}
	&__title {
		font-size: 26px;
		line-height: 125%;
	}
	&__subtitle {
		margin-top: 30px;
		font-size: 20px;
		font-weight: 700;
	}
	&__list {

	}
	&__item {
		margin-top: 12px;
	}
	&__offer {
		position: relative;
		margin-top: 45px;
		line-height: 125%;
		&:before {
			position: absolute;
			content: '';
			bottom: -81px;
			right: 33px;
			width: 80px;
			height: 61px;
			background: url(../img/arrow.svg) top left no-repeat;
			background-size: contain;
		}
	}
	&__btn {
		margin-top: 20px;
		width: 280px;
		height: 80px;
	}
}

.paper {
	position: absolute;
	top: 0;
	left: 50%;
	width: 1480px;
	transform: translateX(-885px);
	&__img {
		padding-top: 51%;
		background: url(../img/paper.png) top left no-repeat;
	}
}