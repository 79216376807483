body,
div,
dl,
dt,
dd,
ul,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
code,
form,
fieldset,
input,
textarea,
p,
blockquote,
th,
td {
  margin: 0;
  padding: 0;
}
div {
  box-sizing: border-box;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
fieldset,
img,
abbr {
  border: 0;
}
address,
caption,
cite,
code,
dfn,
em,
strong,
th,
var {
  font-style: normal;
  font-weight: normal;
}
ul li {
  list-style: none;
}
caption,
th {
  text-align: left;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: 100%;
  font-weight: normal;
}
sup {
  vertical-align: text-top;
}
sub {
  vertical-align: text-bottom;
}
input,
textarea,
select {
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  border: none;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
main,
form {
  display: block;
}
button {
  cursor: pointer;
  border: none;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
input[type='number'] {
  -moz-appearance: textfield;
}
select::-ms-expand {
  display: none;
}
select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
* {
  outline: none;
}
html {
  overflow-x: hidden;
}
@font-face {
  font-family: 'Noah';
  src: url('../fonts/noah400/Noah-Regular.eot');
  src: url('../fonts/noah400/Noah-Regular.eot?#iefix') format('embedded-opentype'), url('../fonts/noah400/Noah-Regular.woff2') format('woff2'), url('../fonts/noah400/Noah-Regular.woff') format('woff'), url('../fonts/noah400/Noah-Regular.ttf') format('truetype'), url('../fonts/noah400/Noah-Regular.svg#Noah-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Noah';
  src: url('../fonts/noah700/Noah-Bold.eot');
  src: url('../fonts/noah700/Noah-Bold.eot?#iefix') format('embedded-opentype'), url('../fonts/noah700/Noah-Bold.woff2') format('woff2'), url('../fonts/noah700/Noah-Bold.woff') format('woff'), url('../fonts/noah700/Noah-Bold.ttf') format('truetype'), url('../fonts/noah700/Noah-Bold.svg#Noah-Bold') format('svg');
  font-weight: bold;
  font-style: normal;
}
img {
  opacity: 1;
  transition: opacity 0.3s;
}
img {
  opacity: 1;
  transition: opacity 0.3s;
}
img[data-src] {
  opacity: 0;
}
*::-webkit-input-placeholder {
  color: #CACACA;
}
*::-moz-placeholder {
  color: #CACACA;
}
*:-moz-placeholder {
  color: #CACACA;
}
*:-ms-input-placeholder {
  color: #CACACA;
}
a {
  text-decoration: none;
}
body {
  position: relative;
  font-family: 'Noah';
  color: #1B1D1F;
  font-weight: 400;
  font-size: 16px;
  background: #fff;
  overflow: hidden;
}
._noScroll {
  overflow: hidden;
}
.wrapper {
  max-width: 1150px;
  padding: 0 20px;
  margin: 0 auto;
}
.section-title {
  font-size: 35px;
  line-height: 130%;
}
.section-subtitle {
  font-size: 21px;
  line-height: 130%;
}
.hidden {
  display: none;
}
.separator {
  height: 1px;
  background: #000;
  opacity: 0.05;
}
.btn {
  position: relative;
  max-width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 80px;
  padding-top: 3px;
  box-sizing: border-box;
  transition: 0.4s;
}
.btn__blind {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: inherit;
  overflow: hidden;
}
.btn__blind:before {
  position: absolute;
  content: '';
  top: -5px;
  left: 0;
  bottom: -5px;
  width: 12px;
  background: #fff;
  z-index: 5;
  animation-name: blind;
  animation-duration: 5s;
  animation-iteration-count: infinite;
  opacity: 0.7;
  box-shadow: 3px 0 8px rgba(255, 255, 255, 0.05);
  transform: rotate(14deg) translateX(10px);
}
.btn__text {
  position: relative;
  color: #1B1D1F;
  text-shadow: 0px 1px 0px rgba(255, 255, 255, 0.75);
  letter-spacing: 0.02em;
  font-size: 12px;
  line-height: 125%;
  font-weight: 700;
  text-transform: uppercase;
  z-index: 2;
}
.btn:before {
  position: absolute;
  content: '';
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: inherit;
  z-index: 1;
  background: linear-gradient(0deg, #FFDE8A, #FFDE8A);
  box-shadow: 0px 10px 25px rgba(255, 217, 121, 0.1), inset 0px -3px 3px rgba(255, 255, 255, 0.12), inset 0px 8px 18px rgba(255, 255, 255, 0.25);
}
.btn:after {
  position: absolute;
  content: '';
  top: 4px;
  left: 1px;
  right: 1px;
  bottom: -4px;
  border-radius: inherit;
  background: #F6CC63;
  z-index: 0;
  transition: 0.4s;
}
.btn:hover {
  transform: translateY(2px);
}
.btn:hover:after {
  top: 2px;
  bottom: -2px;
}
@keyframes blind {
  0% {
    left: -20px;
  }
  15% {
    left: 100%;
  }
  100% {
    left: 100%;
  }
}
.link {
  position: relative;
  color: #548EFF;
  transition: 0.4s;
}
.link:hover {
  color: #216cff;
}
.link:before {
  position: absolute;
  content: '';
  bottom: -4px;
  left: 0;
  right: 0;
  height: 1px;
  background: #548EFF;
  opacity: 0.2;
}
.tab {
  position: relative;
  border-radius: 6px;
  display: block;
  max-width: 100%;
  box-sizing: border-box;
  padding-top: 2px;
}
.tab__text {
  position: relative;
  color: #ACACAC;
  line-height: 125%;
  font-weight: 700;
  text-transform: uppercase;
  z-index: 2;
}
.tab:before {
  position: absolute;
  content: '';
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: inherit;
  background: #F1F1F1;
  z-index: 1;
}
.tab:after {
  position: absolute;
  content: '';
  top: 2px;
  left: 0;
  right: 0;
  bottom: -2px;
  border-radius: 8px;
  background: #F1F1F1;
  z-index: 0;
}
.tab._active:before {
  background: linear-gradient(0deg, #FFDE8A, #FFDE8A);
}
.tab._active:after {
  background: #F6CC63;
}
.tab._active > .tab__text {
  color: #1B1D1F;
  text-shadow: 0px 1px 0px rgba(255, 255, 255, 0.75);
}
.social {
  display: flex;
  margin-left: -4px;
  margin-right: -4px;
}
.social__item {
  display: block;
  width: 28px;
  height: 28px;
  border-radius: 28px;
  margin-left: 4px;
  margin-right: 4px;
  transition: 0.4s;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
}
.social__item._whatsapp {
  background: url(../img/whatsapp.svg) center no-repeat, linear-gradient(180deg, #56FFAD 0%, #1DE374 100%, #36F189 100%);
}
.social__item._telegram {
  background: url(../img/telegram.svg) center no-repeat, linear-gradient(360deg, #56C3F3 0%, #67D0FE 100%);
}
.social__item._viber {
  background: url(../img/viber.svg) center no-repeat, linear-gradient(180deg, #9187FF 0%, #8075F7 100%);
}
.social__item._vk {
  background: url(../img/vk.svg) center no-repeat, linear-gradient(180deg, #72ADF0 0%, #6199D9 100%);
}
.social__item:hover {
  animation: socialRotate 1s;
}
@keyframes socialRotate {
  0 {
    transform: none;
  }
  25% {
    transform: rotate(15deg);
  }
  75% {
    transform: rotate(-15deg);
  }
  100% {
    transform: none;
  }
}
.form__bg {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: inherit;
  background: #fff;
  z-index: -1;
}
.form__bg:before {
  position: absolute;
  content: '';
  top: 5px;
  left: 5px;
  right: 5px;
  bottom: 5px;
  border-radius: 4px;
  border: 1px solid #000;
  opacity: 0.05;
}
.form__input {
  border-radius: 2px;
  border: 1px solid rgba(0, 0, 0, 0.05);
  padding-left: 38px;
  box-sizing: border-box;
}
.form__policy {
  font-size: 12px;
}
.policy__link {
  position: relative;
  color: #548EFF;
  transition: 0.4s;
}
.policy__link:hover {
  color: #216cff;
}
.policy__link:before {
  position: absolute;
  content: '';
  bottom: -4px;
  left: 0;
  right: 0;
  height: 1px;
  background: #548EFF;
  opacity: 0.2;
}
.navbar-wrap {
  position: absolute;
  top: 17px;
  left: 0;
  right: 0;
  z-index: 2;
}
.navbar {
  display: flex;
}
.navbar__descriptor {
  max-width: 178px;
  line-height: 130%;
}
.navbar__nav {
  margin: 0 auto;
}
.navbar__link {
  font-size: 12px;
  color: #1B1D1F;
  margin-left: 38px;
  margin-right: 38px;
  transition: 0.4s;
}
.navbar__link:hover {
  color: #548EFF;
}
.contacts__title {
  display: block;
  font-size: 14px;
}
.contacts__phone {
  display: block;
  margin-top: 5px;
  font-size: 20px;
  font-weight: 700;
  color: #1B1D1F;
}
.contacts__email {
  position: relative;
  color: #548EFF;
  transition: 0.4s;
  display: block;
  margin-top: 4px;
  font-size: 14px;
}
.contacts__email:hover {
  color: #216cff;
}
.contacts__email:before {
  position: absolute;
  content: '';
  bottom: -4px;
  left: 0;
  right: 0;
  height: 1px;
  background: #548EFF;
  opacity: 0.2;
}
.contacts__social {
  margin-top: 21px;
}
.header {
  position: relative;
  padding-top: 239px;
  background: url(../img/header.jpg) center top no-repeat;
  background-size: 100% auto;
}
.thm {
  position: absolute;
  top: 24px;
  left: 50%;
  width: 629px;
  transform: translateX(-1248px);
  z-index: 0;
}
.thm__bg {
  padding-top: 75%;
  background: url(../img/thm.png) center top no-repeat;
}
.wex {
  position: absolute;
  top: 198px;
  left: 50%;
  width: 771px;
  transform: translateX(459px);
  z-index: 0;
}
.wex__bg {
  padding-top: 85%;
  background: url(../img/wex.png) center top no-repeat;
}
.main__title {
  font-size: 45px;
  line-height: 115%;
  max-width: 805px;
}
.main__offer {
  margin-top: 10px;
  font-size: 28px;
}
.main__btn {
  margin-top: 35px;
  width: 345px;
  height: 80px;
}
.features {
  margin-top: 96px;
  display: flex;
  margin-left: -5px;
  margin-right: -5px;
  transform: translateY(15px);
}
.feature {
  position: relative;
  background: #FFFFFF;
  box-shadow: 0px 25px 55px rgba(0, 0, 0, 0.08);
  border-radius: 2px;
  padding-top: 29px;
  padding-bottom: 29px;
  padding-right: 15px;
  margin-left: 5px;
  margin-right: 5px;
  width: calc(100% / 4 - 10px);
}
.feature._1 {
  padding-left: 65px;
}
.feature._2 {
  padding-left: 110px;
}
.feature._3 {
  padding-left: 100px;
}
.feature._4 {
  padding-left: 131px;
}
.feature__icon {
  position: absolute;
}
.feature__icon._1 {
  top: 5px;
  left: -20px;
}
.feature__icon._2 {
  top: 16px;
  left: -10px;
}
.feature__icon._3 {
  top: -4px;
  left: 19px;
}
.feature__icon._4 {
  top: 16px;
  left: 9px;
}
.feature:before {
  position: absolute;
  content: '';
  top: 5px;
  left: 5px;
  right: 5px;
  bottom: 5px;
  border-radius: inherit;
  border: 1px solid #000;
  opacity: 0.05;
}
.feature__text {
  font-size: 18px;
  font-weight: 700;
}
.products {
  margin-top: 140px;
}
.products__title {
  max-width: 883px;
  margin: 0 auto;
  text-align: center;
}
.products__subtitle {
  margin-top: 32px;
  text-align: center;
}
.products__row {
  margin-top: 10px;
  display: none;
}
.products__row._active {
  display: flex;
}
.products__cards {
  width: 855px;
  max-width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-left: -10px;
  margin-right: -10px;
  flex-shrink: 0;
}
.products__specifications {
  margin-top: 20px;
  margin-left: 20px;
  flex-grow: 1;
}
.products__tabs {
  margin-top: 48px;
  display: flex;
  justify-content: center;
}
.products__tab {
  width: 250px;
  height: 70px;
  line-height: 70px;
  margin-left: 15px;
  margin-right: 15px;
  text-align: right;
  padding-right: 40px;
}
.products__tab-img {
  position: absolute;
  height: 100px;
  width: 132px;
  bottom: -2px;
  left: -20px;
  z-index: 2;
}
.products__tab-img._thm {
  background: url(../img/thm_small.png) left top no-repeat;
}
.products__tab-img._wex {
  background: url(../img/wex_small.png) left top no-repeat;
}
.products__card {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  width: calc(100% / 2 - 20px);
  margin-left: 10px;
  margin-right: 10px;
  box-shadow: 0px 20px 55px rgba(0, 0, 0, 0.1);
  padding: 30px 36px 30px 40px;
  border-radius: 2px;
  min-height: 126px;
  z-index: 1;
}
.products__card-bg {
  position: absolute;
  top: 0;
  left: 26px;
  right: 0;
  bottom: 0;
  background: #fff;
  z-index: -1;
  border-radius: inherit;
}
.products__card-bg:before {
  position: absolute;
  content: '';
  top: 0;
  left: -26px;
  bottom: 0;
  width: 27px;
  background: url(../img/products-shape.svg) center left no-repeat;
  background-size: contain;
}
.products__card-bg:after {
  position: absolute;
  content: '';
  top: 5px;
  left: 0;
  right: 5px;
  bottom: 5px;
  border: 1px solid #000;
  border-left: none;
  opacity: 0.05;
}
.products__card-text {
  font-size: 16px;
  line-height: 135%;
}
.products__card:before {
  position: absolute;
  content: '';
  left: -4px;
  top: 10px;
  bottom: 10px;
  right: 0;
  background: linear-gradient(270deg, #FFD976 0%, #FFDE8A 100%);
  border-radius: inherit;
  opacity: 0.7;
  z-index: -1;
}
.specifications {
  margin-top: 20px;
}
.specifications__title {
  font-weight: 700;
}
.specifications__list {
  margin-top: 15px;
}
.specifications__item {
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  margin-top: 12px;
  align-items: flex-start;
}
.specifications__link {
  position: relative;
  color: #548EFF;
  transition: 0.4s;
  display: inline-block;
  margin-top: 20px;
  font-size: 14px;
}
.specifications__link:hover {
  color: #216cff;
}
.specifications__link:before {
  position: absolute;
  content: '';
  bottom: -4px;
  left: 0;
  right: 0;
  height: 1px;
  background: #548EFF;
  opacity: 0.2;
}
.documents {
  position: relative;
  margin-top: 120px;
  padding-top: 140px;
  color: #fff;
}
.documents__row {
  position: relative;
  max-width: 422px;
  z-index: 2;
}
.documents__title {
  font-size: 26px;
  line-height: 125%;
}
.documents__subtitle {
  margin-top: 30px;
  font-size: 20px;
  font-weight: 700;
}
.documents__item {
  margin-top: 12px;
}
.documents__offer {
  position: relative;
  margin-top: 45px;
  line-height: 125%;
}
.documents__offer:before {
  position: absolute;
  content: '';
  bottom: -81px;
  right: 33px;
  width: 80px;
  height: 61px;
  background: url(../img/arrow.svg) top left no-repeat;
  background-size: contain;
}
.documents__btn {
  margin-top: 20px;
  width: 280px;
  height: 80px;
}
.paper {
  position: absolute;
  top: 0;
  left: 50%;
  width: 1480px;
  transform: translateX(-885px);
}
.paper__img {
  padding-top: 51%;
  background: url(../img/paper.png) top left no-repeat;
}
.equipment {
  margin-top: 298px;
  margin-bottom: 120px;
}
.equipment__row {
  display: flex;
}
.equipment__text {
  flex-grow: 0;
}
.equipment__title {
  max-width: 628px;
  font-size: 30px;
  line-height: 120%;
}
.equipment__subtitle {
  margin-top: 45px;
  font-size: 23px;
  font-weight: 700;
}
.equipment__list {
  margin-top: 25px;
  max-width: 366px;
}
.equipment__item {
  position: relative;
  margin-top: 10px;
  padding-left: 18px;
}
.equipment__item:before {
  position: absolute;
  content: '';
  top: 9px;
  left: 0;
  width: 8px;
  height: 2px;
  background: #548EFF;
}
.equipment__item.hidden {
  display: none;
}
.equipment__item:last-child {
  font-size: 0.8em;
  width: 250px;
  max-width: 100%;
  opacity: 0.65;
  line-height: 120%;
}
.equipment__item:last-child:before {
  display: none;
}
.equipment__item:last-child > a {
  color: #548EFF;
  white-space: nowrap;
}
.equipment__link {
  position: relative;
  color: #548EFF;
  transition: 0.4s;
  margin-top: 10px;
  display: inline-block;
  font-size: 15px;
}
.equipment__link:hover {
  color: #216cff;
}
.equipment__link:before {
  position: absolute;
  content: '';
  bottom: -4px;
  left: 0;
  right: 0;
  height: 1px;
  background: #548EFF;
  opacity: 0.2;
}
.equipment__form {
  position: relative;
  width: 415px;
  max-width: 100%;
  margin-left: auto;
}
.equipment__figure {
  position: absolute;
  top: 31px;
  left: -265px;
  width: 695px;
  z-index: -1;
}
.equipment__figure-bg {
  padding-top: 82%;
  background: url(../img/figure.png) top left no-repeat;
}
.eq-f {
  position: relative;
  border-radius: 5px;
  box-shadow: 0px 20px 65px rgba(0, 0, 0, 0.08);
  padding: 55px 45px;
}
.eq-f__title {
  font-size: 23px;
  font-weight: 700;
}
.eq-f__subtitle {
  font-size: 17px;
  line-height: 130%;
}
.eq-f__hint {
  display: block;
  margin-top: 18px;
  margin-bottom: 8px;
  font-size: 15px;
}
.eq-f__messengers {
  display: flex;
  margin-left: -4px;
  margin-right: -4px;
}
.eq-f__messengers-item {
  margin-left: 4px;
  margin-right: 4px;
}
.eq-f__messengers-radio {
  display: none;
}
.eq-f__messengers-label {
  min-width: 32px;
  height: 32px;
  border-radius: 32px;
  display: inline-block;
  cursor: pointer;
}
.eq-f__messengers-label._email {
  background: url(../img/mail.svg) center no-repeat, linear-gradient(180deg, #4BBEFF 0%, #42A6DF 100%);
}
.eq-f__messengers-label._whatsapp {
  background: url(../img/whatsapp.svg) center no-repeat, linear-gradient(180deg, #56FFAD 0%, #1DE374 100%, #36F189 100%);
}
.eq-f__messengers-label._telegram {
  background: url(../img/telegram.svg) center no-repeat, linear-gradient(360deg, #56C3F3 0%, #67D0FE 100%);
}
.eq-f__messengers-label._viber {
  background: url(../img/viber.svg) center no-repeat, linear-gradient(180deg, #9187FF 0%, #8075F7 100%);
}
.eq-f__messengers-name {
  font-size: 14px;
  line-height: 32px;
  color: #fff;
  font-weight: 700;
  display: none;
}
.eq-f__input {
  width: 100%;
  height: 80px;
}
.eq-f__btn {
  margin-top: 15px;
  height: 88px;
}
.eq-f__policy {
  display: block;
  margin: 15px auto 0;
  text-align: center;
  max-width: 226px;
}
.eq-f__messengers-radio:checked + .eq-f__messengers-label > .eq-f__messengers-name {
  display: block;
}
.eq-f__messengers-radio:checked + .eq-f__messengers-label._email {
  padding-left: 32px;
  padding-right: 15px;
  background: url(../img/mail.svg) center left 15px no-repeat, linear-gradient(180deg, #4BBEFF 0%, #42A6DF 100%);
}
.eq-f__messengers-radio:checked + .eq-f__messengers-label._whatsapp {
  padding-left: 32px;
  padding-right: 15px;
  background: url(../img/whatsapp.svg) center left 15px no-repeat, linear-gradient(180deg, #56FFAD 0%, #1DE374 100%, #36F189 100%);
}
.eq-f__messengers-radio:checked + .eq-f__messengers-label._telegram {
  padding-left: 32px;
  padding-right: 15px;
  background: url(../img/telegram.svg) center left 15px no-repeat, linear-gradient(360deg, #56C3F3 0%, #67D0FE 100%);
}
.eq-f__messengers-radio:checked + .eq-f__messengers-label._viber {
  padding-left: 32px;
  padding-right: 15px;
  background: url(../img/viber.svg) center left 15px no-repeat, linear-gradient(180deg, #9187FF 0%, #8075F7 100%);
}
.benefit {
  margin-top: 100px;
}
.benefit__title {
  max-width: 688px;
  margin: 0 auto;
  text-align: center;
}
.benefit__row {
  position: relative;
  margin-top: 60px;
}
.benefit__tabs {
  position: absolute;
  top: 0;
  left: 0;
  width: 580px;
  max-width: 100%;
  display: flex;
  margin-left: -5px;
  margin-right: -5px;
}
.benefit__tab {
  width: calc(100% / 2 - 10px);
  margin-left: 5px;
  margin-right: 5px;
  height: 72px;
  line-height: 72px;
  text-align: center;
}
.benefit__item {
  display: none;
}
.benefit__item._active {
  display: flex;
}
.benefit__photos {
  margin-top: 77px;
  width: 570px;
  flex-shrink: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.benefit__photo {
  width: calc(100% / 3 - 4px);
  margin-top: 5px;
  overflow: hidden;
  border-radius: 2px;
}
.benefit__photo._preview {
  width: 100%;
}
.benefit__photo-bg {
  display: block;
  width: 100%;
  padding-top: 59.5%;
  background: #000;
  background-size: cover;
  background-position: center;
  transition: 0.8s;
}
.benefit__photo-bg:hover {
  transform: scale(1.04);
}
.benefit__info {
  margin-top: 15px;
  margin-left: 24px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.benefit__name {
  font-size: 20px;
  line-height: 130%;
}
.benefit__desc {
  margin-top: 20px;
  max-width: 487px;
  line-height: 135%;
}
.benefit__link {
  position: relative;
  color: #548EFF;
  display: inline-block;
  margin-top: 15px;
  padding-left: 19px;
  background: url(../img/zoom.png) top 2px left no-repeat;
  background-size: 15px;
  font-size: 15px;
  transition: 0.4s;
  display: none;
}
.benefit__link:hover {
  color: #216cff;
}
.benefit__link:before {
  position: absolute;
  content: '';
  bottom: -4px;
  left: 0;
  right: 0;
  height: 1px;
  background: #548EFF;
  opacity: 0.2;
}
.benefit__link:hover {
  background-size: 18px;
  background-position: top left;
  padding-left: 23px;
}
.benefit__offer {
  position: relative;
  margin-top: 28px;
  padding: 30px 45px;
  border-radius: 2px;
}
.benefit__offer:before {
  position: absolute;
  content: '';
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(0deg, #FFDE8A, #FFDE8A);
  border-radius: inherit;
  opacity: 0.4;
  z-index: -1;
}
.benefit__offer-text {
  font-size: 20px;
  line-height: 120%;
}
.benefit__offer-mark {
  display: block;
  margin-top: 7px;
  font-size: 15px;
  font-weight: 700;
}
.benefit__btn {
  margin-top: auto;
  margin-bottom: 4px;
  width: 335px;
  height: 85px;
  text-align: center;
}
.questions {
  margin-top: 120px;
}
.questions__title {
  text-align: center;
}
.consult {
  position: relative;
  width: 630px;
  max-width: 100%;
  margin: 50px auto 0;
  padding: 50px 115px;
  border-radius: 6px;
  transform: translateX(120px);
}
.consult__bg {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: inherit;
  z-index: -2;
  box-shadow: 0px 20px 55px rgba(0, 0, 0, 0.08);
}
.consult__bg:before {
  position: absolute;
  content: '';
  top: 5px;
  left: 5px;
  right: 5px;
  bottom: 5px;
  border-radius: inherit;
  border: 1px solid #000;
  opacity: 0.05;
}
.consult__laptop {
  position: absolute;
  top: -22px;
  left: -259px;
  z-index: -1;
  width: 562px;
}
.consult__laptop-bg {
  padding-top: 82%;
  background: url(../img/laptop.png) top left no-repeat;
}
.consult-form__title {
  font-size: 23px;
  font-weight: 700;
}
.consult-form__subtitle {
  display: block;
  font-size: 17px;
}
.consult-form__hint {
  display: block;
  margin-top: 15px;
  font-size: 15px;
  color: #B0B0BB;
}
.consult-form__area {
  margin-top: 5px;
  display: flex;
  background: #fff;
  border-radius: 2px;
  border: 1px solid rgba(0, 0, 0, 0.05);
}
.consult-form__method {
  position: relative;
}
.consult-form__input {
  height: 80px;
  margin-left: 140px;
  padding-left: 25px;
}
.consult-form__btn {
  margin: 15px auto 0;
  padding-top: 5px;
  height: 80px;
  width: 290px;
}
.consult-form__policy {
  display: block;
  margin: 15px auto 0;
  max-width: 226px;
  text-align: center;
}
.methods {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  background: #fff;
  padding: 5px 5px 0;
  z-index: 5;
  transition: 0.5s;
}
.methods__radio {
  display: none;
}
.methods__label {
  position: relative;
  display: none;
  margin-bottom: 5px;
  padding-top: 1px;
  box-sizing: border-box;
  width: 130px;
  height: 70px;
  border-radius: 2px;
  text-align: center;
  order: 2;
  cursor: pointer;
}
.methods__label:before {
  position: absolute;
  content: '';
  width: 13px;
  height: 7px;
  right: 14px;
  top: 50%;
  transform: translateY(-50%);
  background: url(../img/chevron.svg) center no-repeat;
  background-size: cover;
}
.methods__label:after {
  position: absolute;
  content: '';
  top: 50%;
  transform: translateY(-50%);
  width: 15px;
  height: 15px;
}
.methods__label._email {
  background: linear-gradient(180deg, #4BBEFF 0%, #42A6DF 100%);
}
.methods__label._email:after {
  background: url(../img/mail.svg) center no-repeat;
  left: 27px;
}
.methods__label._whatsapp {
  background: linear-gradient(180deg, #56FFAD 0%, #1DE374 100%, #36F189 100%);
}
.methods__label._whatsapp:after {
  background: url(../img/whatsapp.svg) center no-repeat;
  left: 17px;
}
.methods__label._telegram {
  background: linear-gradient(360deg, #56C3F3 0%, #67D0FE 100%);
}
.methods__label._telegram:after {
  background: url(../img/telegram.svg) center no-repeat;
  left: 23px;
}
.methods__label._viber {
  background: linear-gradient(180deg, #9187FF 0%, #8075F7 100%);
}
.methods__label._viber:after {
  background: url(../img/viber.svg) center no-repeat;
  left: 31px;
}
.methods__name {
  font-size: 14px;
  font-weight: 700;
  color: #fff;
  line-height: 70px;
}
.methods:hover {
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.07);
}
.methods:hover .methods__label {
  display: block;
}
.methods:hover .methods__label:before {
  display: none;
}
.methods__radio:checked + .methods__label {
  display: block;
  order: 1;
}
.footer {
  margin-top: 100px;
  color: #9CA2AB;
  padding-bottom: 80px;
}
.footer__descriptor {
  display: inline-block;
  max-width: 178px;
  line-height: 140%;
}
.footer__row {
  display: inline-flex;
  vertical-align: top;
  padding-left: 5px;
  flex-wrap: wrap;
  width: calc(100% - 188px);
  justify-content: space-between;
  align-items: flex-start;
}
.footer__item {
  width: calc(100% / 3 - 120px);
  margin-left: 120px;
}
.footer__item._email {
  padding-top: 4px;
  text-align: right;
}
.footer__item._phone {
  padding-top: 4px;
  padding-left: 38px;
}
.footer__item._developer {
  text-align: right;
}
.footer__social {
  display: flex;
  align-items: center;
}
.footer__social-title {
  margin-right: 25px;
  font-size: 14px;
}
.footer__phone {
  position: relative;
  color: #1B1D1F;
  font-weight: 700;
}
.footer__phone:after {
  position: absolute;
  content: '';
  top: -4px;
  left: -38px;
  width: 28px;
  height: 28px;
  border-radius: 28px;
  background: url(../img/phone.svg) center no-repeat, linear-gradient(180deg, #4BBEFF 0%, #42A6DF 100%);
}
.footer__email {
  position: relative;
  color: #548EFF;
  transition: 0.4s;
  font-size: 14px;
}
.footer__email:hover {
  color: #216cff;
}
.footer__email:before {
  position: absolute;
  content: '';
  bottom: -4px;
  left: 0;
  right: 0;
  height: 1px;
  background: #548EFF;
  opacity: 0.2;
}
.footer__email:after {
  position: absolute;
  content: '';
  top: -4px;
  left: -38px;
  width: 28px;
  height: 28px;
  border-radius: 28px;
  background: url(../img/mail.svg) center no-repeat, linear-gradient(#4BBEFF 0%, #42A6DF 100%);
  background-size: 12px;
}
.footer__policy,
.footer__reserved,
.footer__developer {
  font-size: 12px;
  display: inline-block;
  margin-top: 20px;
}
.footer__policy {
  color: inherit;
  position: relative;
  transition: 0.4s;
}
.footer__policy:hover {
  color: #548EFF;
}
.footer__policy:before {
  position: absolute;
  content: '';
  bottom: -4px;
  left: 0;
  right: 0;
  height: 1px;
  background: #9CA2AB;
  opacity: 0.2;
}
.footer__developer {
  text-align: right;
}
.footer__developer-link {
  position: relative;
  color: #548EFF;
  transition: 0.4s;
}
.footer__developer-link:hover {
  color: #216cff;
}
.footer__developer-link:before {
  position: absolute;
  content: '';
  bottom: -4px;
  left: 0;
  right: 0;
  height: 1px;
  background: #548EFF;
  opacity: 0.2;
}
.modal {
  position: relative;
  width: 630px;
  max-width: 100%;
  margin: 50px auto 0;
  padding: 50px 115px;
  border-radius: 6px;
  transform: translateX(120px);
  z-index: 2;
}
.modal__bg {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: inherit;
  z-index: -2;
  background: #fff;
  box-shadow: 0px 20px 55px rgba(0, 0, 0, 0.08);
}
.modal__bg:before {
  position: absolute;
  content: '';
  top: 5px;
  left: 5px;
  right: 5px;
  bottom: 5px;
  border-radius: inherit;
  border: 1px solid #000;
  opacity: 0.05;
}
.modal__laptop {
  position: absolute;
  top: -22px;
  left: -259px;
  z-index: -1;
  width: 562px;
}
.modal__laptop-bg {
  padding-top: 82%;
  background: url(../img/laptop.png) top left no-repeat;
}
.modal-form__title {
  font-size: 23px;
  font-weight: 700;
}
.modal-form__subtitle {
  display: block;
  font-size: 17px;
}
.modal-form__hint {
  display: block;
  margin-top: 15px;
  font-size: 15px;
  color: #B0B0BB;
}
.modal-form__area {
  margin-top: 5px;
  display: flex;
  background: #fff;
  border-radius: 2px;
  border: 1px solid rgba(0, 0, 0, 0.05);
}
.modal-form__method {
  position: relative;
}
.modal-form__input {
  height: 80px;
  margin-left: 140px;
  padding-left: 25px;
}
.modal-form__btn {
  margin: 15px auto 0;
  padding-top: 5px;
  height: 80px;
  width: 340px;
}
.modal-form__policy {
  display: block;
  margin: 15px auto 0;
  max-width: 226px;
  text-align: center;
}
.modal-gallery {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100000000;
  justify-content: center;
  align-items: center;
  display: none;
}
.modal-gallery img {
  margin: 0 auto;
  max-width: 95%;
}
.modal-gallery._active {
  display: flex;
}
.modal-gallery._active .modal__overlay {
  opacity: 0.3;
  animation: overlayFade 0.5s linear;
}
.modal-gallery._active .modal__cnt {
  animation: fadeInTop 0.5s linear;
}
.modal__overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #000;
  opacity: 0;
  cursor: pointer;
  transition: 0.5s;
}
.modal__wrap {
  max-width: calc(100vw - 20px);
  position: relative;
}
.modal__content {
  text-align: center;
}
.modal__img {
  max-width: calc(100vw - 20px);
  margin: 10px auto;
  max-height: calc(100vh - 20px);
}
.modal__close {
  position: absolute !important;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  top: -28px;
  right: 0;
  z-index: 3;
  transform: rotate(45deg);
  background: transparent;
  transition: 0.4s transform;
}
.modal__close:before,
.modal__close:after {
  position: absolute;
  content: '';
  width: 20px;
  height: 2px;
  background: #DCE1E9;
}
.modal__close:after {
  transform-origin: center;
  transform: rotate(90deg);
}
.modal__close:hover {
  transform: rotate(-45deg);
}
@keyframes overlayFade {
  from {
    opacity: 0;
  }
  to {
    opacity: 0.3;
  }
}
@keyframes fadeInTop {
  from {
    transform: translateY(-30px);
  }
  to {
    transform: none;
  }
}
@media screen and (max-width: 780px) {
  .modal {
    transform: none;
    padding-right: 40px;
  }
  .modal-form__input {
    background: none;
  }
}
@media screen and (max-width: 520px) {
  .modal__laptop {
    display: none;
  }
  .modal {
    padding: 35px 25px;
  }
  .modal-form__input {
    margin-left: 110px;
  }
  .methods__label {
    width: 120px;
  }
  .methods__label._whatsapp:after {
    left: 11px;
  }
  .methods__label._telegram:after {
    left: 17px;
  }
  .methods__label._viber:after {
    left: 26px;
  }
  .methods__label._email:after {
    left: 23px;
  }
}
.thermal {
  position: relative;
  width: 1110px;
  max-width: 100%;
  margin: 100px auto 200px;
}
.thermal .mfp-close {
  right: 17px;
}
.thermal__content {
  position: relative;
  padding: 75px 55px;
  border-radius: 8px;
  clip-path: polygon(0 0, 100% 0, 100% 110%, 0 110%);
}
.thermal__bg {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: inherit;
  background: #fff;
  z-index: -1;
}
.thermal__bg:before {
  position: absolute;
  content: '';
  top: 7px;
  left: 7px;
  right: 7px;
  bottom: 7px;
  border-radius: 6px;
  border: 1px solid #000;
  opacity: 0.05;
}
.thermal__title {
  font-size: 23px;
  font-weight: 700;
}
.thermal__row {
  margin-top: 30px;
  display: flex;
  margin-left: -10px;
  margin-right: -10px;
}
.thermal__photos {
  width: 495px;
  flex-shrink: 0;
  margin-left: 10px;
  margin-right: 10px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.thermal__photo {
  width: calc(100% / 3 - 4px);
  margin-top: 5px;
  overflow: hidden;
  border-radius: 2px;
}
.thermal__photo._preview {
  width: 100%;
}
.thermal__photo-bg {
  display: block;
  width: 100%;
  padding-top: 59.5%;
  background: #000;
  background-size: cover;
  background-position: center;
  transition: 0.8s;
}
.thermal__photo-bg:hover {
  transform: scale(1.04);
}
.thermal__info {
  margin-left: 10px;
  margin-right: 10px;
}
.thermal__desc {
  line-height: 135%;
}
.thermal__subtitle {
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 15px;
}
.thermal__subtitle._characteristics {
  margin-top: 13px;
}
.thermal__subtitle._construct {
  margin-top: 30px;
}
.thermal__subtitle._electric {
  margin-top: 20px;
}
.thermal__parameters {
  position: relative;
  width: 660px;
  max-width: 100%;
}
.thermal__figure {
  position: absolute;
  width: 700px;
  top: 0;
  left: 666px;
}
.thermal__figure-bg {
  padding-top: 134%;
}
.thermal__figure-bg._thm {
  background: url(../img/figure_thm.png) top left no-repeat;
}
.thermal__list-item {
  padding: 10px 30px;
  display: flex;
  justify-content: space-between;
}
.thermal__list-item:nth-child(odd) {
  background: rgba(0, 0, 0, 0.05);
}
.thermal__list-item._voltage {
  display: block;
}
.thermal__voltage {
  margin-top: 5px;
  display: flex;
  justify-content: space-between;
}
.thermal__value {
  flex-shrink: 0;
}
.thermal__value-voltage {
  display: block;
  margin-top: 8px;
}
.thermal__value-voltage:first-child {
  margin-top: 0;
}
.thermal__form {
  margin-top: 50px;
}
.thermal-form__title {
  font-size: 23px;
  font-weight: 700;
}
.thermal-form__subtitle {
  display: block;
  font-size: 17px;
  max-width: 490px;
}
.thermal-form__hint {
  display: block;
  margin-top: 15px;
  font-size: 15px;
  color: #B0B0BB;
}
.thermal-form__wrap {
  display: flex;
}
.thermal-form__area {
  margin-top: 5px;
  width: 400px;
  max-width: 100%;
  display: flex;
  background: #fff;
  border-radius: 2px;
  border: 1px solid rgba(0, 0, 0, 0.05);
}
.thermal-form__method {
  position: relative;
}
.thermal-form__input {
  height: 76px;
  margin-left: 140px;
  padding-left: 25px;
}
.thermal-form__btn {
  margin-left: 15px;
  padding-top: 5px;
  height: 80px;
  width: 340px;
}
.thermal-form__policy {
  margin-top: 15px;
  display: block;
}
@media screen and (max-width: 1150px) {
  .thermal__row {
    align-items: flex-start;
  }
  .thermal__photos {
    width: 44%;
    align-items: flex-start;
  }
  .thermal__photo,
  .thermal__photo:first-child {
    width: calc(100% / 2 - 3px);
  }
}
@media screen and (max-width: 960px) {
  .thermal__row {
    flex-wrap: wrap;
  }
  .thermal__photos {
    width: calc(100% - 20px);
  }
  .thermal__info {
    margin-top: 25px;
  }
  .thermal__figure {
    display: none;
  }
  .thermal__content {
    padding: 50px 35px;
  }
}
@media screen and (max-width: 780px) {
  .thermal__title {
    font-size: 20px;
  }
  .thermal__desc {
    font-size: 14px;
  }
  .thermal__list-item {
    padding: 10px 15px;
    font-size: 14px;
  }
  .thermal__position {
    margin-right: 20px;
  }
  .thermal__content {
    padding: 40px 20px;
    margin-left: -10px;
    margin-right: -10px;
  }
  .thermal-form__title {
    font-size: 20px;
  }
  .thermal-form__subtitle {
    font-size: 16px;
  }
  .thermal-form__wrap {
    flex-wrap: wrap;
  }
  .thermal-form__btn {
    margin-top: 14px;
    margin-left: 0;
  }
}
@media screen and (max-width: 520px) {
  .thermal__title {
    font-size: 19px;
  }
  .thermal__content {
    padding: 40px 10px;
    margin-left: -15px;
    margin-right: -15px;
  }
  .thermal__bg:before {
    display: none;
  }
  .thermal__list-item {
    font-size: 12px;
    flex-wrap: wrap;
  }
  .thermal-form__input {
    background: none;
    padding-left: 110px;
    margin-left: 30px;
  }
}
/* Magnific Popup CSS */
.mfp-bg {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1042;
  overflow: hidden;
  position: fixed;
  background: #0b0b0b;
  opacity: 0.8;
}
.mfp-wrap {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1043;
  position: fixed;
  outline: none !important;
  -webkit-backface-visibility: hidden;
}
.mfp-container {
  text-align: center;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  padding: 0 8px;
  box-sizing: border-box;
}
.mfp-container:before {
  content: '';
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}
.mfp-align-top .mfp-container:before {
  display: none;
}
.mfp-content {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  margin: 0 auto;
  text-align: left;
  z-index: 1045;
}
.mfp-inline-holder .mfp-content,
.mfp-ajax-holder .mfp-content {
  width: 100%;
  cursor: auto;
}
.mfp-ajax-cur {
  cursor: progress;
}
.mfp-zoom-out-cur,
.mfp-zoom-out-cur .mfp-image-holder .mfp-close {
  cursor: -moz-zoom-out;
  cursor: -webkit-zoom-out;
  cursor: zoom-out;
}
.mfp-zoom {
  cursor: pointer;
  cursor: -webkit-zoom-in;
  cursor: -moz-zoom-in;
  cursor: zoom-in;
}
.mfp-auto-cursor .mfp-content {
  cursor: auto;
}
.mfp-close,
.mfp-arrow,
.mfp-preloader,
.mfp-counter {
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}
.mfp-loading.mfp-figure {
  display: none;
}
.mfp-hide {
  display: none !important;
}
.mfp-preloader {
  color: #CCC;
  position: absolute;
  top: 50%;
  width: auto;
  text-align: center;
  margin-top: -0.8em;
  left: 8px;
  right: 8px;
  z-index: 1044;
}
.mfp-preloader a {
  color: #CCC;
}
.mfp-preloader a:hover {
  color: #FFF;
}
.mfp-s-ready .mfp-preloader {
  display: none;
}
.mfp-s-error .mfp-content {
  display: none;
}
button.mfp-close,
button.mfp-arrow {
  overflow: visible;
  cursor: pointer;
  background: transparent;
  border: 0;
  -webkit-appearance: none;
  display: block;
  outline: none;
  padding: 0;
  z-index: 1046;
  box-shadow: none;
  touch-action: manipulation;
}
button::-moz-focus-inner {
  padding: 0;
  border: 0;
}
.mfp-close {
  width: 44px;
  height: 44px;
  line-height: 44px;
  position: absolute;
  right: 0;
  top: 0;
  text-decoration: none;
  text-align: center;
  opacity: 0.65;
  padding: 0 0 18px 10px;
  color: #FFF;
  font-style: normal;
  font-size: 28px;
  font-family: Arial, Baskerville, monospace;
}
.mfp-close:hover,
.mfp-close:focus {
  opacity: 1;
}
.mfp-close:active {
  top: 1px;
}
.mfp-close-btn-in .mfp-close {
  color: #333;
}
.mfp-image-holder .mfp-close,
.mfp-iframe-holder .mfp-close {
  color: #FFF;
  right: -6px;
  text-align: right;
  padding-right: 6px;
  width: 100%;
}
.mfp-counter {
  position: absolute;
  top: 0;
  right: 0;
  color: #CCC;
  font-size: 12px;
  line-height: 18px;
  white-space: nowrap;
}
.mfp-arrow {
  position: absolute;
  opacity: 0.65;
  margin: 0;
  top: 50%;
  margin-top: -55px;
  padding: 0;
  width: 90px;
  height: 110px;
  -webkit-tap-highlight-color: transparent;
}
.mfp-arrow:active {
  margin-top: -54px;
}
.mfp-arrow:hover,
.mfp-arrow:focus {
  opacity: 1;
}
.mfp-arrow:before,
.mfp-arrow:after {
  content: '';
  display: block;
  width: 0;
  height: 0;
  position: absolute;
  left: 0;
  top: 0;
  margin-top: 35px;
  margin-left: 35px;
  border: medium inset transparent;
}
.mfp-arrow:after {
  border-top-width: 13px;
  border-bottom-width: 13px;
  top: 8px;
}
.mfp-arrow:before {
  border-top-width: 21px;
  border-bottom-width: 21px;
  opacity: 0.7;
}
.mfp-arrow-left {
  left: 0;
}
.mfp-arrow-left:after {
  border-right: 17px solid #FFF;
  margin-left: 31px;
}
.mfp-arrow-left:before {
  margin-left: 25px;
  border-right: 27px solid #3F3F3F;
}
.mfp-arrow-right {
  right: 0;
}
.mfp-arrow-right:after {
  border-left: 17px solid #FFF;
  margin-left: 39px;
}
.mfp-arrow-right:before {
  border-left: 27px solid #3F3F3F;
}
.mfp-iframe-holder {
  padding-top: 40px;
  padding-bottom: 40px;
}
.mfp-iframe-holder .mfp-content {
  line-height: 0;
  width: 100%;
  max-width: 900px;
}
.mfp-iframe-holder .mfp-close {
  top: -40px;
}
.mfp-iframe-scaler {
  width: 100%;
  height: 0;
  overflow: hidden;
  padding-top: 56.25%;
}
.mfp-iframe-scaler iframe {
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
  background: #000;
}
/* Main image in popup */
img.mfp-img {
  width: auto;
  max-width: 100%;
  height: auto;
  display: block;
  line-height: 0;
  box-sizing: border-box;
  padding: 40px 0 40px;
  margin: 0 auto;
}
/* The shadow behind the image */
.mfp-figure {
  line-height: 0;
}
.mfp-figure:after {
  content: '';
  position: absolute;
  left: 0;
  top: 40px;
  bottom: 40px;
  display: block;
  right: 0;
  width: auto;
  height: auto;
  z-index: -1;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
  background: #444;
}
.mfp-figure small {
  color: #BDBDBD;
  display: block;
  font-size: 12px;
  line-height: 14px;
}
.mfp-figure figure {
  margin: 0;
}
.mfp-bottom-bar {
  margin-top: -36px;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  cursor: auto;
}
.mfp-title {
  text-align: left;
  line-height: 18px;
  color: #F3F3F3;
  word-wrap: break-word;
  padding-right: 36px;
}
.mfp-image-holder .mfp-content {
  max-width: 100%;
}
.mfp-gallery .mfp-image-holder .mfp-figure {
  cursor: pointer;
}
@media screen and (max-width: 800px) and (orientation: landscape), screen and (max-height: 300px) {
  /**
       * Remove all paddings around the image on small screen
       */
  .mfp-img-mobile .mfp-image-holder {
    padding-left: 0;
    padding-right: 0;
  }
  .mfp-img-mobile img.mfp-img {
    padding: 0;
  }
  .mfp-img-mobile .mfp-figure:after {
    top: 0;
    bottom: 0;
  }
  .mfp-img-mobile .mfp-figure small {
    display: inline;
    margin-left: 5px;
  }
  .mfp-img-mobile .mfp-bottom-bar {
    background: rgba(0, 0, 0, 0.6);
    bottom: 0;
    margin: 0;
    top: auto;
    padding: 3px 5px;
    position: fixed;
    box-sizing: border-box;
  }
  .mfp-img-mobile .mfp-bottom-bar:empty {
    padding: 0;
  }
  .mfp-img-mobile .mfp-counter {
    right: 5px;
    top: 3px;
  }
  .mfp-img-mobile .mfp-close {
    top: 0;
    right: 0;
    width: 35px;
    height: 35px;
    line-height: 35px;
    background: rgba(0, 0, 0, 0.6);
    position: fixed;
    text-align: center;
    padding: 0;
  }
}
@media all and (max-width: 900px) {
  .mfp-arrow {
    -webkit-transform: scale(0.75);
    transform: scale(0.75);
  }
  .mfp-arrow-left {
    -webkit-transform-origin: 0;
    transform-origin: 0;
  }
  .mfp-arrow-right {
    -webkit-transform-origin: 100%;
    transform-origin: 100%;
  }
  .mfp-container {
    padding-left: 6px;
    padding-right: 6px;
  }
}
@media screen and (max-width: 1400px) {
  .wex {
    transform: translateX(359px);
  }
  .thm {
    transform: translateX(-1128px);
  }
}
@media screen and (max-width: 1150px) {
  .thm {
    display: none;
  }
  .wex {
    display: none;
  }
  .header {
    padding-top: 190px;
  }
  .header__row {
    display: flex;
  }
  .main {
    margin-top: 50px;
    flex-grow: 1;
  }
  .main__title {
    font-size: 36px;
    max-width: 780px;
  }
  .features {
    display: block;
    transform: none;
    width: 270px;
    flex-shrink: 0;
    margin: 0;
  }
  .feature {
    width: 100%;
    margin-top: 10px;
  }
  .products__row {
    flex-wrap: wrap;
    justify-content: center;
  }
  .specifications {
    max-width: 855px;
    margin-left: auto;
    margin-right: auto;
  }
  .specifications__list {
    display: flex;
    flex-wrap: wrap;
    margin-left: -10px;
    margin-right: -10px;
  }
  .specifications__item {
    white-space: nowrap;
    margin-left: 10px;
    margin-right: 10px;
  }
  .specifications__name {
    margin-right: 20px;
  }
  .equipment__form {
    flex-shrink: 0;
  }
  .equipment__figure {
    left: -185px;
  }
  .equipment__title {
    font-size: 26px;
    max-width: 500px;
  }
  .benefit__item {
    flex-wrap: wrap;
  }
  .benefit__photos {
    margin-top: 40px;
    width: 100%;
    order: 2;
  }
  .benefit__tabs {
    position: static;
    margin-left: auto;
    margin-right: auto;
  }
  .benefit__info {
    flex-direction: row;
    flex-wrap: wrap;
    margin-left: 0;
    margin-top: 35px;
  }
  .benefit__name {
    width: 100%;
  }
  .benefit__link {
    order: 2;
  }
  .benefit__offer {
    width: calc(100% - 507px);
    margin-top: 20px;
    margin-left: 20px;
    padding: 20px 30px;
  }
  .benefit__offer-text {
    font-size: 16px;
  }
  .benefit__btn {
    margin-top: 30px;
  }
  .footer__item {
    width: calc(100% / 3 - 70px);
    margin-left: 70px;
  }
  .footer__developer {
    white-space: nowrap;
  }
}
@media screen and (max-width: 960px) {
  .wex {
    display: block;
    top: 308px;
    width: 531px;
    transform: translateX(149px);
  }
  .wex__bg {
    background-size: contain;
  }
  .navbar__descriptor {
    font-size: 14px;
  }
  .navbar__link {
    margin: 0 20px;
  }
  .header {
    padding-top: 140px;
    background-size: cover;
  }
  .header__row {
    display: block;
  }
  .features {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    transform: translateY(25px);
  }
  .feature {
    width: 310px;
  }
  .products__card-text {
    font-size: 14px;
  }
  .paper__img {
    background-size: contain;
  }
  .paper {
    width: 1270px;
    transform: translateX(-725px);
  }
  .equipment {
    margin-top: 300px;
  }
  .equipment__form {
    width: 365px;
  }
  .eq-f__btn {
    font-size: 10px;
    height: 75px;
  }
  .eq-f {
    padding: 45px 35px;
  }
  .eq-f__title {
    font-size: 21px;
  }
  .eq-f__subtitle {
    font-size: 16px;
  }
  .equipment__figure-bg {
    background-size: contain;
  }
  .equipment__figure {
    width: 615px;
    left: -105px;
  }
  .equipment__row {
    position: relative;
  }
  .equipment__title {
    position: absolute;
    left: 50%;
    bottom: 100%;
    text-align: center;
    transform: translate(-50%, -50px);
    max-width: 600px;
    width: 600px;
  }
  .benefit__offer {
    position: absolute;
    width: 200px;
    margin: 0;
    right: 0;
    padding: 35px 25px;
  }
  .benefit__name {
    font-size: 18px;
    width: 450px;
  }
  .benefit__desc {
    max-width: 100%;
    width: calc(100% - 220px);
    font-size: 14px;
  }
  .benefit__offer-mark {
    font-size: 11px;
    white-space: nowrap;
  }
  .benefit__offer-text {
    font-size: 13px;
  }
  .section-title {
    font-size: 29px;
  }
  .products__title {
    max-width: 740px;
  }
  .consult {
    transform: translateX(50px);
  }
  .footer__item {
    width: calc(100% / 2 - 70px);
  }
  .footer__item._phone {
    margin-top: 12px;
    text-align: right;
    order: 3;
  }
  .footer__item._policy {
    order: 2;
  }
  .footer__item._reserved {
    order: 4;
  }
  .footer__item._developer {
    order: 5;
  }
}
@media screen and (max-width: 780px) {
  .navbar__nav {
    display: none;
  }
  .navbar__contacts {
    margin-left: auto;
  }
  .main__title {
    font-size: 32px;
    max-width: 700px;
  }
  .main__offer {
    font-size: 23px;
  }
  .feature {
    width: calc(100% / 2 - 10px);
    max-width: 270px;
  }
  .feature._1 {
    padding-left: 55px;
  }
  .feature._2 {
    padding-left: 90px;
  }
  .feature._3 {
    padding-left: 67px;
  }
  .feature._4 {
    padding-left: 111px;
  }
  .feature__icon._2 {
    width: 100px;
    left: -17px;
  }
  .feature__icon._3 {
    left: -5px;
  }
  .feature__icon._4 {
    width: 93px;
    left: -3px;
  }
  .feature__text {
    display: block;
    font-size: 15px;
  }
  .feature__text._1 {
    max-width: 150px;
  }
  .feature__text._2 {
    max-width: 120px;
  }
  .feature__text._3 {
    max-width: 150px;
  }
  .feature__text._4 {
    max-width: 85px;
  }
  .section-title {
    font-size: 21px;
  }
  .products__title {
    max-width: 539px;
  }
  .products__tab {
    margin-left: 10px;
    margin-right: 10px;
    padding-right: 30px;
    font-size: 15px;
  }
  .section-subtitle {
    font-size: 18px;
  }
  .products__cards {
    justify-content: center;
  }
  .products__card {
    width: 530px;
    max-width: 100%;
  }
  .paper {
    transform: translateX(-615px);
  }
  .documents__row {
    max-width: 382px;
  }
  .documents__title {
    font-size: 20px;
  }
  .documents__subtitle {
    font-size: 17px;
  }
  .documents__item {
    font-size: 14px;
  }
  .documents__offer {
    font-size: 14px;
    margin-top: 35px;
    max-width: 300px;
    line-height: 130%;
  }
  .equipment {
    margin-top: 170px;
  }
  .equipment__text {
    width: 100%;
  }
  .equipment__title {
    position: static;
    width: 100%;
    transform: none;
    font-size: 21px;
    max-width: 360px;
    text-align: left;
  }
  .equipment__row {
    flex-wrap: wrap;
  }
  .equipment__subtitle {
    margin-top: 35px;
    font-size: 18px;
  }
  .equipment__item {
    font-size: 14px;
  }
  .equipment__list {
    max-width: 306px;
  }
  .equipment__link {
    margin-top: 15px;
    font-size: 13px;
  }
  .equipment__form {
    margin-top: 35px;
    position: static;
    width: 100%;
  }
  .equipment__figure {
    left: 320px;
  }
  .eq-f__subtitle {
    display: block;
    max-width: 300px;
  }
  .eq-f__hint {
    font-size: 14px;
  }
  .eq-f__btn {
    width: 330px;
  }
  .eq-f__policy {
    margin-left: 20px;
    text-align: left;
  }
  .benefit__tabs {
    margin-left: -5px;
    margin-right: -5px;
  }
  .benefit__offer {
    position: relative;
    margin-top: 20px;
    margin-left: 20px;
  }
  .consult__bg {
    display: none;
  }
  .consult {
    padding-right: 0;
    transform: none;
  }
  .consult__laptop {
    left: -220px;
  }
  .footer__item {
    width: 100%;
    margin: 20px auto 0;
    text-align: center !important;
  }
  .footer__item._email {
    padding-left: 30px;
  }
  .footer__item._phone {
    margin-top: 20px;
  }
  .footer__descriptor {
    display: block;
    margin: 0 auto;
    text-align: center;
  }
  .footer__row {
    display: block;
    width: 100%;
  }
  .footer__social {
    justify-content: center;
    flex-wrap: wrap;
  }
  .footer__social-title {
    width: 100%;
    text-align: center;
    margin-right: 0;
    margin-bottom: 10px;
  }
}
@media screen and (max-width: 520px) {
  .contacts__phone {
    font-size: 17px;
  }
  .contacts__title {
    font-size: 12px;
  }
  .contacts__social {
    margin-top: 15px;
  }
  .contacts__email {
    font-size: 13px;
  }
  .navbar__descriptor {
    font-size: 12px;
    max-width: 135px;
  }
  .main__title > b {
    display: block;
    font-size: 24px;
    margin-bottom: 4px;
  }
  .main__title {
    font-size: 19px;
    max-width: 350px;
  }
  .main__offer {
    font-size: 16px;
  }
  .btn__text {
    font-size: 10px;
  }
  .main__btn {
    height: 70px;
    width: 255px;
    padding: 0 20px;
    text-align: center;
    margin-top: 15px;
  }
  .feature {
    width: 100%;
  }
  .wex {
    top: 238px;
    transform: translateX(28px);
  }
  .thm {
    display: block;
    left: auto;
    right: -290px;
    top: 420px;
    transform: scaleX(-1);
    width: 519px;
  }
  .thm__bg {
    background-size: contain;
  }
  .products__tabs {
    flex-wrap: wrap;
    margin-top: 18px;
  }
  .products__tab {
    margin-top: 15px;
    width: auto;
    flex-basis: 189px;
    flex-grow: 1;
    text-align: left;
    padding-left: 80px;
    padding-right: 0;
    height: 60px;
    line-height: 60px;
  }
  .products__tab-img {
    height: 80px;
    background-size: contain !important;
    left: -15px;
  }
  .products__card {
    margin-left: 0;
    margin-right: 0;
    padding: 25px 16px 25px 37px;
  }
  .specifications__item {
    width: 100%;
  }
  .products__specifications {
    margin-left: 0;
  }
  .equipment__title {
    font-size: 18px;
  }
  .equipment__subtitle {
    font-size: 16px;
  }
  .eq-f {
    padding: 0;
    box-shadow: none;
  }
  .eq-f__bg {
    display: none;
  }
  .benefit__row {
    margin-top: 20px;
  }
  .benefit__tabs {
    flex-wrap: wrap;
    margin: 0;
  }
  .tab > .tab__text {
    font-size: 13px;
  }
  .benefit__tab {
    margin-top: 10px;
    width: 100%;
    margin-left: 0;
    margin-right: 0;
  }
  .benefit__name {
    width: 100%;
  }
  .benefit__offer {
    width: 100%;
    margin-left: 0;
  }
  .benefit__offer-text {
    font-size: 14px;
  }
  .benefit__desc {
    width: 100%;
  }
  .benefit__btn {
    width: 100%;
    max-width: 300px;
    margin-top: 15px;
    padding: 0 20px;
  }
  .benefit__photos {
    margin-top: 25px;
  }
  .benefit__photo {
    width: calc(100% / 2 - 3px);
  }
  .benefit__photo:first-child {
    width: calc(100% / 2 - 3px);
  }
  .consult__laptop {
    display: none;
  }
  .consult {
    padding: 0;
  }
  .inputs {
    width: 100%;
  }
  .consult-form__input {
    width: 100%;
    margin-left: 0;
    padding-left: 165px;
    background: none;
  }
}
@media screen and (max-width: 355px) {
  .navbar {
    margin: 0 -10px;
  }
  .main__title > b {
    display: block;
    font-size: 21px;
    margin-bottom: 4px;
  }
  .main__title {
    font-size: 16px;
    max-width: 350px;
  }
  .section-title {
    font-size: 18px;
  }
  .section-subtitle {
    font-size: 15px;
  }
}
.policy-page {
  margin: 150px 0 150px;
}
.policy__title {
  font-size: 35px;
  font-weight: 700;
}
.policy__subtitle {
  font-size: 20px;
  font-weight: 700;
  margin-top: 30px;
}
.policy__text,
.policy__list {
  margin-top: 20px;
  line-height: 145%;
}
.policy__list-item {
  margin-top: 10px;
}
.policy__link {
  position: relative;
  color: #548EFF;
  transition: 0.4s;
}
.policy__link:hover {
  color: #216cff;
}
.policy__link:before {
  position: absolute;
  content: '';
  bottom: -4px;
  left: 0;
  right: 0;
  height: 1px;
  background: #548EFF;
  opacity: 0.2;
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .benefit__info {
    width: calc(100% - 570px);
  }
  .benefit__offer {
    display: block;
    max-width: 100%;
  }
  .benefit__name {
    display: block;
    max-width: 100%;
  }
  .footer__item {
    width: calc(100% / 3 - 120px);
    flex: 1 1 auto;
    margin-left: 110px;
  }
}
