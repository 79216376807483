@import 'settings';
@import 'navbar';
@import 'header';
@import 'products';
@import 'documents';
@import 'equipment';
@import 'benefit';
@import 'questions';
@import 'footer';
@import 'modal';
@import 'thermal';
@import 'magnific-popup';
@import 'queries';
@import 'policy';


@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
	.benefit__info
	{
		width: calc(100% - 570px);
	}
	.benefit__offer
	{
		display: block;
		max-width: 100%;
	}
	.benefit__name
	{
		display: block;
		max-width: 100%;
	}
	.footer__item
	{
		width: calc(100% /3 - 120px);
		flex: 1 1 auto;
		margin-left: 110px;
	}
}