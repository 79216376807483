.policy {
	&-page {
		margin: 150px 0 150px;
	}
	&__title {
		font-size: 35px;
		font-weight: 700;
	}
	&__subtitle {
		font-size: 20px;
		font-weight: 700;
		margin-top: 30px;
	}
	&__text, &__list {
		margin-top: 20px;
		line-height: 145%;
	}
	&__list-item {
		margin-top: 10px;
	}
	&__link {
		.link()
	}
}