@font-face {
    font-family: 'Noah';
    src: url('../fonts/noah400/Noah-Regular.eot');
    src: url('../fonts/noah400/Noah-Regular.eot?#iefix') format('embedded-opentype'),
        url('../fonts/noah400/Noah-Regular.woff2') format('woff2'),
        url('../fonts/noah400/Noah-Regular.woff') format('woff'),
        url('../fonts/noah400/Noah-Regular.ttf') format('truetype'),
        url('../fonts/noah400/Noah-Regular.svg#Noah-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Noah';
    src: url('../fonts/noah700/Noah-Bold.eot');
    src: url('../fonts/noah700/Noah-Bold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/noah700/Noah-Bold.woff2') format('woff2'),
        url('../fonts/noah700/Noah-Bold.woff') format('woff'),
        url('../fonts/noah700/Noah-Bold.ttf') format('truetype'),
        url('../fonts/noah700/Noah-Bold.svg#Noah-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
}