.thermal {
	position: relative;
	width: 1110px;
	max-width: 100%;
	margin: 100px auto 200px;
	.mfp-close {
		right: 17px;
	}
	&__content {
		position: relative;
		padding: 75px 55px;
		border-radius: 8px;
		clip-path: polygon(0 0, 100% 0, 100% 110%, 0 110%);
	}
	&__bg {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		border-radius: inherit;
		background: #fff;
		z-index: -1;
		&:before {
			position: absolute;
			content: '';
			top: 7px;
			left: 7px;
			right: 7px;
			bottom: 7px;
			border-radius: 6px;
			border: 1px solid #000;
			opacity: .05;
		}
	}
	&__title {
		font-size: 23px;
		font-weight: 700;
	}
	&__row {
		margin-top: 30px;
		display: flex;
		margin-left: -10px;
		margin-right: -10px;
	}
	&__photos {
		width: 495px;
		flex-shrink: 0;
		margin-left: 10px;
		margin-right: 10px;
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
	}
	&__photo {
		width: calc(100% / 3 - 4px);
		margin-top: 5px;
		overflow: hidden;
		border-radius: 2px;
		&._preview {
			width: 100%;
		}
		&-bg {
			display: block;
			width: 100%;
			padding-top: 59.5%;
			background: #000;
			background-size: cover;
			background-position: center;
			transition: .8s;
			&:hover {
				transform: scale(1.04);
			}
		}
	}
	&__info {
		margin-left: 10px;
		margin-right: 10px;
	}
	&__desc {
		line-height: 135%;
	}
	&__subtitle {
		font-size: 18px;
		font-weight: 700;
		margin-bottom: 15px;
		&._characteristics {
			margin-top: 13px;
		}
		&._construct {
			margin-top: 30px;
		}
		&._electric {
			margin-top: 20px;
		}
	}
	&__parameters {
		position: relative;
		width: 660px;
		max-width: 100%;
	}
	&__figure {
		position: absolute;
		width: 700px;
		top: 0;
		left: 666px;
		&-bg {
			padding-top: 134%;
			&._thm {
				background: url(../img/figure_thm.png) top left no-repeat;
			}
		}
	}
	&__list {
		&-item {
			padding: 10px 30px;
			display: flex;
			justify-content: space-between;
			&:nth-child(odd) {
				background: rgba(0,0,0,.05);
			}
			&._voltage {
				display: block;
			}
		}
	}
	&__voltage {
		margin-top: 5px;
		display: flex;
		justify-content: space-between;
	}
	&__value {
		flex-shrink: 0;
		&-voltage {
			display: block;
			margin-top: 8px;
			&:first-child {
				margin-top: 0;
			}
		}
	}
	&__form {
		margin-top: 50px;
	}
}

.thermal-form {
	&__title {
		font-size: 23px;
		font-weight: 700;
	}
	&__subtitle {
		display: block;
		font-size: 17px;
		max-width: 490px;
	}
	&__hint {
		display: block;
		margin-top: 15px;
		font-size: 15px;
		color: #B0B0BB;
	}
	&__wrap {
		display: flex;
	}
	&__area {
		margin-top: 5px;
		width: 400px;
		max-width: 100%;
		display: flex;
		background: #fff;
		border-radius: 2px;
		border: 1px solid rgba(0,0,0,.05);
	}
	&__method {
		position: relative;
	}
	&__inputs {

	}
	&__input {
		height: 76px;
		margin-left: 140px;
		padding-left: 25px;
	}
	&__btn {
		margin-left: 15px;
		padding-top: 5px;
		height: 80px;
		width: 340px;
	}
	&__policy {
		margin-top: 15px;
		display: block;
	}
}

@media screen and (max-width: 1150px)
{
	.thermal__row
	{
		align-items: flex-start;
	}
	.thermal__photos
	{
		width: 44%;
		align-items: flex-start;
	}
	.thermal__photo, .thermal__photo:first-child
	{
		width: calc(100% / 2 - 3px);
	}
}

@media screen and (max-width: 960px)
{
	.thermal__row
	{
		flex-wrap: wrap;
	}
	.thermal__photos
	{
		width: calc(100% - 20px);
	}
	.thermal__info
	{
		margin-top: 25px;
	}
	.thermal__figure
	{
		display: none;
	}
	.thermal__content
	{
		padding: 50px 35px;
	}
}

@media screen and (max-width: 780px)
{
	.thermal__title
	{
		font-size: 20px;
	}
	.thermal__desc
	{
		font-size: 14px;
	}
	.thermal__list-item
	{
		padding: 10px 15px;
		font-size: 14px;
	}
	.thermal__position
	{
		margin-right: 20px;
	}
	.thermal__content
	{
		padding: 40px 20px;
		margin-left: -10px;
		margin-right: -10px;
	}
	.thermal-form__title
	{
		font-size: 20px;
	}
	.thermal-form__subtitle
	{
		font-size: 16px;
	}
	.thermal-form__wrap
	{
		flex-wrap: wrap;
	}
	.thermal-form__btn
	{
		margin-top: 14px;
		margin-left: 0;
	}
}

@media screen and (max-width: 520px)
{
	.thermal__title
	{
		font-size: 19px;
	}
	.thermal__content
	{
		padding: 40px 10px;
		margin-left: -15px;
		margin-right: -15px;
	}
	.thermal__bg:before
	{
		display: none;
	}
	.thermal__list-item
	{
		font-size: 12px;
		flex-wrap: wrap;
	}
	.thermal-form__input
	{
		background: none;
		padding-left: 110px;
		margin-left: 30px;
	}
}