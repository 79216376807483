@import 'reset';
@import 'variables';
@import 'fonts';

img {
  opacity: 1;
  transition: opacity 0.3s;
}

img {
  opacity: 1;
  transition: opacity 0.3s;
}
 
img[data-src] {
  opacity: 0;
}

* {
	&::-webkit-input-placeholder {color:#CACACA;}
	&::-moz-placeholder          {color:#CACACA;}
	&:-moz-placeholder           {color:#CACACA;}
	&:-ms-input-placeholder      {color:#CACACA;}
}

a {
	text-decoration: none;
}

body {
	position: relative;
	font-family: @font;
	color: @main;
	font-weight: 400;
	font-size: 16px;
	background: #fff;
	overflow: hidden;
}

._noScroll
{
	overflow: hidden;
}

.wrapper {
	max-width: 1150px;
	padding: 0 20px;
	margin: 0 auto;
}

.section {
	&-title {
		font-size: 35px;
		line-height: 130%;
	}
	&-subtitle {
		font-size: 21px;
		line-height: 130%;
	}
}

.hidden {
	display: none;
}

.separator {
	height: 1px;
	background: #000;
	opacity: .05;
}

.btn {
	position: relative;
	max-width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 80px;
	padding-top: 3px;
	box-sizing: border-box;
	transition: .4s;
	&__blind {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		border-radius: inherit;
		overflow: hidden;
		&:before 
		{
			position: absolute;
			content: '';
			top: -5px;
			left: 0;
			bottom: -5px;
			width: 12px;
			background: #fff;
			z-index: 5;
			animation-name: blind;
			animation-duration: 5s;
			animation-iteration-count: infinite;
			opacity: .7;
			box-shadow: 3px 0 8px rgba(255,255,255,.05);
			transform: rotate(14deg) translateX(10px);
		}
	}
	&__text {
		position: relative;
		color: @main;
		text-shadow: 0px 1px 0px rgba(255, 255, 255, 0.75);
		letter-spacing: 0.02em;
		font-size: 12px;
		line-height: 125%;
		font-weight: 700;
		text-transform: uppercase;
		z-index: 2;
	}
	&:before {
		position: absolute;
		content: '';
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		border-radius: inherit;
		z-index: 1;
		background: linear-gradient(0deg, #FFDE8A, #FFDE8A);
		box-shadow: 0px 10px 25px rgba(255, 217, 121, 0.1), inset 0px -3px 3px rgba(255, 255, 255, 0.12), inset 0px 8px 18px rgba(255, 255, 255, 0.25);
	}
	&:after {
		position: absolute;
		content: '';
		top: 4px;
		left: 1px;
		right: 1px;
		bottom: -4px;
		border-radius: inherit;
		background: #F6CC63;
		z-index: 0;
		transition: .4s;
	}
	&:hover {
		transform: translateY(2px);
		&:after {
			top: 2px;
			bottom: -2px;
		}
	}
}

@keyframes blind{
	0%
	{
		left: -20px;
	}
	15% {
		left: 100%;
	}
	100%
	{
		left: 100%;
	}
}

.link {
	position: relative;
	color: @link;
	transition: .4s;
	&:hover {
		color: darken(@link, 10%);
	}
	&:before {
		position: absolute;
		content: '';
		bottom: -4px;
		left: 0;
		right: 0;
		height: 1px;
		background: @link;
		opacity: .2;
	}
}

.tab {
	position: relative;
	border-radius: 6px;
	display: block;
	max-width: 100%;
	box-sizing: border-box;
	padding-top: 2px;
	&__text {
		position: relative;
		color: #ACACAC;
		line-height: 125%;
		font-weight: 700;
		text-transform: uppercase;
		z-index: 2;
	}
	&:before {
		position: absolute;
		content: '';
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		border-radius: inherit;
		background: #F1F1F1;
		z-index: 1;
	}
	&:after {
		position: absolute;
		content: '';
		top: 2px;
		left: 0;
		right: 0;
		bottom: -2px;
		border-radius: 8px;
		background: #F1F1F1;
		z-index: 0;
	}
	&._active {
		&:before {
			background: linear-gradient(0deg, #FFDE8A, #FFDE8A);
		}
		&:after {
			background: #F6CC63;
		}
		& > .tab__text {
			color: @main;
			text-shadow: 0px 1px 0px rgba(255, 255, 255, 0.75);
		}
	}
}

.social {
	display: flex;
	margin-left: -4px;
	margin-right: -4px;
	&__item {
		display: block;
		width: 28px;
		height: 28px;
		border-radius: 28px;
		margin-left: 4px;
		margin-right: 4px;
		transition: .4s;
		box-shadow: 0 5px 10px rgba(0,0,0,.1);
		&._whatsapp {
			background: url(../img/whatsapp.svg) center no-repeat, linear-gradient(180deg, #56FFAD 0%, #1DE374 100%, #36F189 100%);
		}
		&._telegram {
			background: url(../img/telegram.svg) center no-repeat, linear-gradient(360deg, #56C3F3 0%, #67D0FE 100%);
		}
		&._viber {
			background: url(../img/viber.svg) center no-repeat, linear-gradient(180deg, #9187FF 0%, #8075F7 100%);
		}
		&._vk {
			background: url(../img/vk.svg) center no-repeat, linear-gradient(180deg, #72ADF0 0%, #6199D9 100%);
		}
		&:hover {
			animation: socialRotate 1s;
		}
	}
}

@keyframes socialRotate 
{
	0 {
		transform: none;
	}
	25% {
		transform: rotate(15deg);
	}
	75% {
		transform: rotate(-15deg);
	}
	100% {
		transform: none;
	}
}

.form {
	&__bg {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		border-radius: inherit;
		background: #fff;
		z-index: -1;
		&:before {
			position: absolute;	
			content: '';
			top: 5px;
			left: 5px;
			right: 5px;
			bottom: 5px;
			border-radius: 4px;
			border: 1px solid #000;
			opacity: .05;
		}
	}
	&__input {
		border-radius: 2px;
		border: 1px solid rgba(0,0,0,.05);
		padding-left: 38px;
		box-sizing: border-box;
	}
	&__policy {
		font-size: 12px;
	}
}

.policy__link {
	.link();
}