.questions {
	margin-top: 120px;
	&__title {
		text-align: center;
	}
}

.consult {
	position: relative;
	width: 630px;
	max-width: 100%;
	margin: 50px auto 0;
	padding: 50px 115px;
	border-radius: 6px;
	transform: translateX(120px);
	&__bg {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		border-radius: inherit;
		z-index: -2;
		box-shadow: 0px 20px 55px rgba(0, 0, 0, 0.08);
		&:before {
			position: absolute;
			content: '';
			top: 5px;
			left: 5px;
			right: 5px;
			bottom: 5px;
			border-radius: inherit;
			border: 1px solid #000;
			opacity: .05;
		}
	}
	&__form {

	}
	&__laptop {
		position: absolute;
		top: -22px;
		left: -259px;
		z-index: -1;
		width: 562px;
		&-bg {
			padding-top: 82%;
			background: url(../img/laptop.png) top left no-repeat;
		}
	}
}

.consult-form {
	&__title {
		font-size: 23px;
		font-weight: 700;
	}
	&__subtitle {
		display: block;
		font-size: 17px;
	}
	&__hint {
		display: block;
		margin-top: 15px;
		font-size: 15px;
		color: #B0B0BB;
	}
	&__area {
		margin-top: 5px;
		display: flex;
		background: #fff;
		border-radius: 2px;
		border: 1px solid rgba(0,0,0,.05);
	}
	&__method {
		position: relative;
	}
	&__inputs {

	}
	&__input {
		height: 80px;
		margin-left: 140px;
		padding-left: 25px;
	}
	&__btn {
		margin: 15px auto 0;
		padding-top: 5px;
		height: 80px;
		width: 290px;
	}
	&__policy {
		display: block;
		margin: 15px auto 0;
		max-width: 226px;
		text-align: center;
	}
}

.methods {
	position: absolute;
	top: 0;
	left: 0;
	display: flex;
	flex-direction: column;
	background: #fff;
	padding: 5px 5px 0;
	z-index: 5;
	transition: .5s;
	&__radio {
		display: none;
	}
	&__label {
		position: relative;
		display: none;
		margin-bottom: 5px;
		padding-top: 1px;
		box-sizing: border-box;
		width: 130px;
		height: 70px;
		border-radius: 2px;
		text-align: center;
		order: 2;
		cursor: pointer;
		&:before {
			position: absolute;
			content: '';
			width: 13px;
			height: 7px;
			right: 14px;
			top: 50%;
			transform: translateY(-50%);
			background: url(../img/chevron.svg) center no-repeat;
			background-size: cover;
		}
		&:after {
			position: absolute;
			content: '';
			top: 50%;
			transform: translateY(-50%);
			width: 15px;
			height: 15px;
		}
		&._email {
			background: linear-gradient(180deg, #4BBEFF 0%, #42A6DF 100%);
			&:after {
				background: url(../img/mail.svg) center no-repeat;
				left: 27px;
			}
		}
		&._whatsapp {
			background: linear-gradient(180deg, #56FFAD 0%, #1DE374 100%, #36F189 100%);
			&:after {
				background: url(../img/whatsapp.svg) center no-repeat;
				left: 17px;
			}
		}
		&._telegram {
			background: linear-gradient(360deg, #56C3F3 0%, #67D0FE 100%);
			&:after {
				background: url(../img/telegram.svg) center no-repeat;
				left: 23px;
			}
		}
		&._viber {
			background: linear-gradient(180deg, #9187FF 0%, #8075F7 100%);
			&:after {
				background: url(../img/viber.svg) center no-repeat;
				left: 31px;
			}
		}
	}
	&__name {
		font-size: 14px;
		font-weight: 700;
		color: #fff;
		line-height: 70px;
	}
}

.methods:hover {
	box-shadow: 0 10px 20px rgba(0,0,0,.07);
	.methods__label {
		display: block;
		&:before {
			display: none;
		}
	}
}

.methods__radio:checked + .methods__label {
	display: block;
	order: 1;
}