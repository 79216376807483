.equipment {
	margin-top: 298px;
	margin-bottom: 120px;
	&__row {
		display: flex;
	}
	&__text {
		flex-grow: 0;
	}
	&__title {
		max-width: 628px;
		font-size: 30px;
		line-height: 120%;
	}
	&__subtitle {
		margin-top: 45px;
		font-size: 23px;
		font-weight: 700;
	}
	&__list {
		margin-top: 25px;
		max-width: 366px;
	}
	&__item {
		position: relative;
		margin-top: 10px;
		padding-left: 18px;
		&:before {
			position: absolute;
			content: '';
			top: 9px;
			left: 0;
			width: 8px;
			height: 2px;
			background: @link;
		}
		&.hidden{
			display: none;
		}
		&:last-child {
			&:before {
				display: none;
			}
			font-size: .8em;
			width: 250px;
			max-width: 100%;
			opacity: .65;
			line-height: 120%;
			& > a {
				color: @link;
				white-space: nowrap;
			}
		}
	}
	&__link {
		.link();
		margin-top: 10px;
		display: inline-block;
		font-size: 15px;
	}
	&__form {
		position: relative;
		width: 415px;
		max-width: 100%;
		margin-left: auto;
	}
	&__figure {
		position: absolute;
		top: 31px;
		left: -265px;
		width: 695px;
		z-index: -1;
		&-bg {
			padding-top: 82%;
			background: url(../img/figure.png) top left no-repeat;
		}
	}
}

.eq-f {
	position: relative;
	border-radius: 5px;
	box-shadow: 0px 20px 65px rgba(0, 0, 0, 0.08);
	padding: 55px 45px;
	&__title {
		font-size: 23px;
		font-weight: 700;
	}
	&__subtitle {
		font-size: 17px;
		line-height: 130%;
	}
	&__hint {
		display: block;
		margin-top: 18px;
		margin-bottom: 8px;
		font-size: 15px;
	}
	&__messengers {
		display: flex;
		margin-left: -4px;
		margin-right: -4px;
		&-item {
			margin-left: 4px;
			margin-right: 4px;
		}
		&-radio {
			display: none;
		}
		&-label {
			min-width: 32px;
			height: 32px;
			border-radius: 32px;
			display: inline-block;
			cursor: pointer;
			&._email {
				background: url(../img/mail.svg) center no-repeat, linear-gradient(180deg, #4BBEFF 0%, #42A6DF 100%);
			}
			&._whatsapp {
				background: url(../img/whatsapp.svg) center no-repeat, linear-gradient(180deg, #56FFAD 0%, #1DE374 100%, #36F189 100%);
			}
			&._telegram {
				background: url(../img/telegram.svg) center no-repeat, linear-gradient(360deg, #56C3F3 0%, #67D0FE 100%);
			}
			&._viber {
				background: url(../img/viber.svg) center no-repeat, linear-gradient(180deg, #9187FF 0%, #8075F7 100%);
			}
		}
		&-name {
			font-size: 14px;
			line-height: 32px;
			color: #fff;
			font-weight: 700;
			display: none;
		}
	}
	&__inputs {

	}
	&__input {
		width: 100%;
		height: 80px;
	}
	&__btn {
		margin-top: 15px;
		height: 88px;
	}
	&__policy {
		display: block;
		margin: 15px auto 0;
		text-align: center;
		max-width: 226px;
	}
}

.eq-f__messengers-radio:checked + .eq-f__messengers-label {
	& > .eq-f__messengers-name {
		display: block;
	}
	&._email {
		padding-left: 32px;
		padding-right: 15px;
		background: url(../img/mail.svg) center left 15px no-repeat, linear-gradient(180deg, #4BBEFF 0%, #42A6DF 100%);
	}
	&._whatsapp{
		padding-left: 32px;
		padding-right: 15px;
		background: url(../img/whatsapp.svg) center left 15px no-repeat, linear-gradient(180deg, #56FFAD 0%, #1DE374 100%, #36F189 100%);
	}
	&._telegram{
		padding-left: 32px;
		padding-right: 15px;
		background: url(../img/telegram.svg) center left 15px no-repeat, linear-gradient(360deg, #56C3F3 0%, #67D0FE 100%);
	}
	&._viber{
		padding-left: 32px;
		padding-right: 15px;
		background: url(../img/viber.svg) center left 15px no-repeat, linear-gradient(180deg, #9187FF 0%, #8075F7 100%);
	}
}