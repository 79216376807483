@media screen and (max-width: 1400px)
{
	.wex
	{
		transform: translateX(359px);
	}
	.thm
	{
		transform: translateX(-1128px);
	}
}

@media screen and (max-width: 1150px)
{
	.thm
	{
		display: none;
	}
	.wex {
		display: none;
	}
	.header{
		padding-top: 190px;
	}
	.header__row
	{
		display: flex;
	}
	.main
	{
		margin-top: 50px;
		flex-grow: 1;
	}
	.main__title
	{
		font-size: 36px;
		max-width: 780px;
	}
	.features
	{
		display: block;
		transform: none;
		width: 270px;
		flex-shrink: 0;
		margin: 0;
	}
	.feature
	{
		width: 100%;
		margin-top: 10px;
	}
	.products__row
	{
		flex-wrap: wrap;
		justify-content: center;
	}
	.specifications
	{
		max-width: 855px;
		margin-left: auto;
		margin-right: auto;
	}
	.specifications__list
	{
		display: flex;
		flex-wrap: wrap;
		margin-left: -10px;
		margin-right: -10px;
	}
	.specifications__item
	{
		white-space: nowrap;
		margin-left: 10px;
		margin-right: 10px;
	}
	.specifications__name
	{
		margin-right: 20px;
	}
	.equipment__form
	{
		flex-shrink: 0;
	}
	.equipment__figure
	{
		left: -185px;
	}
	.equipment__title
	{
		font-size: 26px;
		max-width: 500px;
	}
	.benefit__item
	{
		flex-wrap: wrap;
	}
	.benefit__photos
	{
		margin-top: 40px;
		width: 100%;
		order: 2;
	}
	.benefit__tabs
	{
		position: static;
		margin-left: auto;
		margin-right: auto;
	}
	.benefit__info
	{
		flex-direction: row;
		flex-wrap: wrap;
		margin-left: 0;
		margin-top: 35px;
	}
	.benefit__name
	{
		width: 100%;
	}
	.benefit__link
	{
		order: 2;
	}
	.benefit__offer
	{
		width: calc(100% - 507px);
		margin-top: 20px;
		margin-left: 20px;
		padding: 20px 30px;
	}
	.benefit__offer-text
	{
		font-size: 16px;
	}
	.benefit__btn
	{
		margin-top: 30px;
	}
	.footer__item
	{
		width: calc(100% / 3 - 70px);
		margin-left: 70px;
	}
	.footer__developer 
	{
		white-space: nowrap;
	}
}

@media screen and (max-width: 960px)
{
	.wex
	{
		display: block;
		top: 308px;
		width: 531px;
		transform: translateX(149px);
	}
	.wex__bg
	{
		background-size: contain;
	}
	.navbar__descriptor
	{
		font-size: 14px;
	}
	.navbar__link
	{
		margin: 0 20px;
	}
	.header 
	{
		padding-top: 140px;
		background-size: cover;
	}
	.header__row
	{
		display: block;
	}
	.features
	{
		display: flex;
		width: 100%;
		flex-wrap: wrap;
		transform: translateY(25px);
	}
	.feature
	{
		width: 310px;
	}
	.products__card-text
	{
		font-size: 14px;
	}
	.paper__img
	{
		background-size: contain;
	}
	.paper
	{
		width: 1270px;
		transform: translateX(-725px);
	}
	.equipment
	{
		margin-top: 300px;
	}
	.equipment__form
	{
		width: 365px;
	}
	.eq-f__btn
	{
		font-size: 10px;
		height: 75px;
	}
	.eq-f
	{
		padding: 45px 35px;
	}
	.eq-f__title
	{
		font-size: 21px;
	}
	.eq-f__subtitle
	{
		font-size: 16px;
	}
	.equipment__figure-bg
	{
		background-size: contain;
	}
	.equipment__figure
	{
		width: 615px;
		left: -105px;
	}
	.equipment__row
	{
		position: relative;
	}
	.equipment__title
	{
		position: absolute;
		left: 50%;
		bottom: 100%;
		text-align: center;
		transform: translate(-50%, -50px);
		max-width: 600px;
		width: 600px;
	}
	.benefit__offer
	{
		position: absolute;
		width: 200px;
		margin: 0;
		right: 0;
		padding: 35px 25px;
	}
	.benefit__name
	{
		font-size: 18px;
		width: 450px;
	}
	.benefit__desc
	{
		max-width: 100%;
		width: calc(100% - 220px);
		font-size: 14px;
	}
	.benefit__offer-mark
	{
		font-size: 11px;
		white-space: nowrap;
	}
	.benefit__offer-text
	{
		font-size: 13px;
	}
	.section-title
	{
		font-size: 29px;
	}
	.products__title 
	{
		max-width: 740px;
	}
	.consult
	{
		transform: translateX(50px);
	}
	.footer__item
	{
		width: calc(100% / 2 - 70px);
	}
	.footer__item._phone
	{
		margin-top: 12px;
		text-align: right;
		order: 3;
	}
	.footer__item._policy
	{
		order: 2;
	}
	.footer__item._reserved
	{
		order: 4;
	}
	.footer__item._developer
	{
		order: 5;
	}
}

@media screen and (max-width: 780px)
{
	.navbar__nav
	{
		display: none;
	}
	.navbar__contacts
	{
		margin-left: auto;
	}
	.main__title 
	{
		font-size: 32px;
		max-width: 700px;
	}
	.main__offer
	{
		font-size: 23px;
	}
	.feature
	{
		width: calc(100% / 2 - 10px);
		max-width: 270px;
		&._1
		{
			padding-left: 55px;
		}
		&._2
		{
			padding-left: 90px;
		}
		&._3
		{
			padding-left: 67px;
		}
		&._4
		{
			padding-left: 111px;
		}
	}
	.feature__icon._2
	{
		width: 100px;
		left: -17px;
	}
	.feature__icon._3
	{
		left: -5px;
	}
	.feature__icon._4
	{
		width: 93px;
		left: -3px;
	}
	.feature__text
	{
		display: block;
		font-size: 15px;
		&._1 {
			max-width: 150px;
		}
		&._2 {
			max-width: 120px;
		}
		&._3 {
			max-width: 150px;
		}
		&._4 {
			max-width: 85px;
		}
	}
	.section-title
	{
		font-size: 21px;
	}
	.products__title
	{
		max-width: 539px;
	}
	.products__tab
	{
		margin-left: 10px;
		margin-right: 10px;
		padding-right: 30px;
		font-size: 15px;
	}
	.section-subtitle
	{
		font-size: 18px;
	}
	.products__cards
	{
		justify-content: center;
	}
	.products__card
	{
		width: 530px;
		max-width: 100%;
	}
	.paper
	{
		transform: translateX(-615px);
	}
	.documents__row
	{
		max-width: 382px;
	}
	.documents__title
	{
		font-size: 20px;
	}
	.documents__subtitle
	{
		font-size: 17px;
	}
	.documents__item
	{
		font-size: 14px;
	}
	.documents__offer
	{
		font-size: 14px;
		margin-top: 35px;
		max-width: 300px;
		line-height: 130%;
	}
	.equipment
	{
		margin-top: 170px;
	}
	.equipment__text
	{
		width: 100%;
	}
	.equipment__title
	{
		position: static;
		width: 100%;
		transform: none;
		font-size: 21px;
		max-width: 360px;
		text-align: left;
	}
	.equipment__row
	{
		flex-wrap: wrap;
	}
	.equipment__subtitle
	{
		margin-top: 35px;
		font-size: 18px;
	}
	.equipment__item 
	{
		font-size: 14px;
	}
	.equipment__list
	{
		max-width: 306px;
	}
	.equipment__link
	{
		margin-top: 15px;
		font-size: 13px;
	}
	.equipment__form
	{
		margin-top: 35px;
		position: static;
		width: 100%;
	}
	.equipment__figure
	{
		left: 320px;
	}
	.eq-f__subtitle
	{
		display: block;
		max-width: 300px;
	}
	.eq-f__hint
	{
		font-size: 14px;
	}
	.eq-f__btn
	{
		width: 330px;
	}
	.eq-f__policy
	{
		margin-left: 20px;
		text-align: left;
	}
	.benefit__tabs
	{
		margin-left: -5px;
		margin-right: -5px;
	}
	.benefit__offer
	{
		position: relative;
		margin-top: 20px;
		margin-left: 20px;
	}
	.consult__bg
	{
		display: none;
	}
	.consult
	{
		padding-right: 0;
		transform: none;
	}
	.consult__laptop
	{
		left: -220px;
	}
	.footer__item
	{
		width: 100%;
		margin: 20px auto 0;
		text-align: center !important;
	}
	.footer__item._email
	{
		padding-left: 30px;
	}
	.footer__item._phone
	{
		margin-top: 20px;
	}
	.footer__descriptor
	{
		display: block;
		margin: 0 auto;
		text-align: center;
	}
	.footer__row
	{
		display: block;
		width: 100%;
	}
	.footer__social
	{
		justify-content: center;
		flex-wrap: wrap;
	}
	.footer__social-title
	{
		width: 100%;
		text-align: center;
		margin-right: 0;
		margin-bottom: 10px;
	}
}

@media screen and(max-width: 520px)
{
	.contacts__phone 
	{
		font-size: 17px;
	}
	.contacts__title
	{
		font-size: 12px;
	}
	.contacts__social
	{
		margin-top: 15px;
	}
	.contacts__email
	{
		font-size: 13px;
	}
	.navbar__descriptor
	{
		font-size: 12px;
		max-width: 135px;
	}
	.main__title > b
	{
		display: block;
		font-size: 24px;
		margin-bottom: 4px;
	}
	.main__title
	{
		font-size: 19px;
		max-width: 350px;
	}
	.main__offer
	{
		font-size: 16px;
	}
	.btn__text
	{
		font-size: 10px;
	}
	.main__btn
	{
		height: 70px;
		width: 255px;
		padding: 0 20px;
		text-align: center;
		margin-top: 15px;
	}
	.feature
	{
		width: 100%;
	}
	.wex
	{
		top: 238px;
		transform: translateX(28px);
	}
	.thm
	{
	    display: block;
	    left: auto;
	    right: -290px;
	    top: 420px;
	    transform: scaleX(-1);
	    width: 519px;
	}
	.thm__bg
	{
		background-size: contain;
	}
	.products__tabs
	{
		flex-wrap: wrap;
		margin-top: 18px;
	}
	.products__tab
	{
		margin-top: 15px;
		width: auto;
		flex-basis: 189px;
		flex-grow: 1;
		text-align: left;
		padding-left: 80px;
		padding-right: 0;
		height: 60px;
		line-height: 60px;
	}
	.products__tab-img
	{
		height: 80px;
		background-size: contain !important;
		left: -15px;
	}
	.products__card
	{
		margin-left: 0;
		margin-right: 0;
		padding: 25px 16px 25px 37px;
	}
	.specifications__item
	{
		width: 100%;
	}
	.products__specifications
	{
		margin-left: 0;
	}
	.equipment__title
	{
		font-size: 18px;
	}
	.equipment__subtitle
	{
		font-size: 16px;
	}
	.eq-f
	{
		padding: 0;
		box-shadow: none;
	}
	.eq-f__bg
	{
		display: none;
	}
	.benefit__row
	{
		margin-top: 20px;
	}
	.benefit__tabs
	{
		flex-wrap: wrap;
		margin: 0;
	}
	.tab > .tab__text
	{
		font-size: 13px;
	}
	.benefit__tab
	{
		margin-top: 10px;
		width: 100%;
		margin-left: 0;
		margin-right: 0;
	}
	.benefit__name
	{
		width: 100%;
	}
	.benefit__offer
	{
		width: 100%;
		margin-left: 0;
	}
	.benefit__offer-text {
		font-size: 14px;
	}
	.benefit__desc
	{
		width: 100%;
	}
	.benefit__btn
	{
		width: 100%;
		max-width: 300px;
		margin-top: 15px;
		padding: 0 20px;
	}
	.benefit__photos
	{
		margin-top: 25px;
	}
	.benefit__photo
	{
		width: calc(100% / 2 - 3px);
	}
	.benefit__photo:first-child 
	{
		width: calc(100% / 2 - 3px);
	}
	.consult__laptop
	{
		display: none;
	}
	.consult
	{
		padding: 0;
	}
	.inputs
	{
		width: 100%;
	}
	.consult-form__input
	{
		width: 100%;
		margin-left: 0;
		padding-left: 165px;
		background: none;
	}
}

@media screen and (max-width: 355px)
{
	.navbar
	{
		margin: 0 -10px;
	}
	.main__title > b
	{
		display: block;
		font-size: 21px;
		margin-bottom: 4px;
	}
	.main__title
	{
		font-size: 16px;
		max-width: 350px;
	}
	.section-title
	{
		font-size: 18px;
	}
	.section-subtitle
	{
		font-size: 15px;
	}
}