.navbar-wrap {
	position: absolute;
	top: 17px;
	left: 0;
	right: 0;
	z-index: 2;
}

.navbar {
	display: flex;
	&__descriptor {
		max-width: 178px;
		line-height: 130%;
	}
	&__nav {
		margin: 0 auto;
	}
	&__link {
		font-size: 12px;
		color: @main;
		margin-left: 38px;
		margin-right: 38px;
		transition: .4s;
		&:hover {
			color: @link;
		}
	}
	&__contacts {
		
	}
}

.contacts {
	&__title {
		display: block;
		font-size: 14px;
	}
	&__phone {
		display: block;
		margin-top: 5px;
		font-size: 20px;
		font-weight: 700;
		color: @main;
	}
	&__email {
		.link();
		display: block;
		margin-top: 4px;
		font-size: 14px;
	}
	&__social {
		margin-top: 21px;
	}
}