.products {
	margin-top: 140px;
	&__title {
		max-width: 883px;
		margin: 0 auto;
		text-align: center;
	}
	&__subtitle {
		margin-top: 32px;
		text-align: center;
	}
	&__row {
		margin-top: 10px;
		display: none;
		&._active {
			display: flex;
		}
	}
	&__cards {
		width: 855px;
		max-width: 100%;
		display: flex;
		flex-wrap: wrap;
		margin-left: -10px;
		margin-right: -10px;
		flex-shrink: 0;
	}
	&__specifications {
		margin-top: 20px;	
		margin-left: 20px;
		flex-grow: 1;
	}
}

.products__tabs {
	margin-top: 48px;
	display: flex;
	justify-content: center;
}

.products__tab {
	width: 250px;
	height: 70px;
	line-height: 70px;
	margin-left: 15px;
	margin-right: 15px;
	text-align: right;
	padding-right: 40px;
	&-img {
		position: absolute;
		height: 100px;
		width: 132px;
		bottom: -2px;
		left: -20px;
		z-index: 2;
		&._thm {
			background: url(../img/thm_small.png) left top no-repeat;
		}
		&._wex {
			background: url(../img/wex_small.png) left top no-repeat;
		}
	}
}

.products__card {
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: 20px;
	width: calc(100% / 2 - 20px);
	margin-left: 10px;
	margin-right: 10px;
	box-shadow: 0px 20px 55px rgba(0, 0, 0, 0.1);
	padding: 30px 36px 30px 40px;
	border-radius: 2px;
	min-height: 126px;
	z-index: 1;
	&-bg {
		position: absolute;
		top: 0;
		left: 26px;
		right: 0;
		bottom: 0;
		background: #fff;
		z-index: -1;
		border-radius: inherit;
		&:before {
			position: absolute;
			content: '';
			top: 0;
			left: -26px;
			bottom: 0;
			width: 27px;
			background: url(../img/products-shape.svg) center left no-repeat;
			background-size: contain;
		}
		&:after {
			position: absolute;
			content: '';
			top: 5px;
			left: 0;
			right: 5px;
			bottom: 5px;
			border: 1px solid #000;
			border-left: none;
			opacity: .05;
		}
	}
	&-text {
		font-size: 16px;
		line-height: 135%;
	}
	&:before {
		position: absolute;
		content: '';
		left: -4px;
		top: 10px;
		bottom: 10px;
		right: 0;
		background: linear-gradient(270deg, #FFD976 0%, #FFDE8A 100%);
		border-radius: inherit;
		opacity: .7;
		z-index: -1;
	}
}

.specifications {
	margin-top: 20px;
	&__title {
		font-weight: 700;
	}
	&__list {
		margin-top: 15px;
	}
	&__item {
		display: flex;
		justify-content: space-between;
		font-size: 14px;
		margin-top: 12px;
		align-items: flex-start;
	}
	&__link {
		.link();
		display: inline-block;
		margin-top: 20px;
		font-size: 14px;
	}
}