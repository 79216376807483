.header {
	position: relative;
	padding-top: 239px;
	background: url(../img/header.jpg) center top no-repeat;
	background-size: 100% auto;
}

.thm {
	position: absolute;
	top: 24px;
	left: 50%;
	width: 629px;
	transform: translateX(-1248px);
	z-index: 0;
	&__bg {
		padding-top: 75%;
		background: url(../img/thm.png) center top no-repeat;
	}
}

.wex {
	position: absolute;
	top: 198px;
	left: 50%;
	width: 771px;
	transform: translateX(459px);
	z-index: 0;
	&__bg {
		padding-top: 85%;
		background: url(../img/wex.png) center top no-repeat;
	}
}

.main {
	&__title {
		font-size: 45px;
		line-height: 115%;
		max-width: 805px;
	}
	&__offer {
		margin-top: 10px;
		font-size: 28px;
	}
	&__btn {
		margin-top: 35px;
		width: 345px;
		height: 80px;
	}
}

.features {
	margin-top: 96px;
	display: flex;
	margin-left: -5px;
	margin-right: -5px;
	transform: translateY(15px);
}

.feature {
	position: relative;
	background: #FFFFFF;
	box-shadow: 0px 25px 55px rgba(0, 0, 0, 0.08);
	border-radius: 2px;
	padding-top: 29px;
	padding-bottom: 29px;
	padding-right: 15px;
	margin-left: 5px;
	margin-right: 5px;
	width: calc(100% / 4 - 10px);
	&._1 {
		padding-left: 65px;
	}
	&._2 {
		padding-left: 110px;
	}
	&._3 {
		padding-left: 100px;
	}
	&._4 {
		padding-left: 131px;
	}
	&__icon {
		position: absolute;
		&._1 {
			top: 5px;
			left: -20px;
		}
		&._2 {
			top: 16px;
			left: -10px;
		}
		&._3 {
			top: -4px;
			left: 19px;
		}
		&._4 {
			top: 16px;
			left: 9px;
		}
	}
	&:before {
		position: absolute;
		content: '';
		top: 5px;
		left: 5px;
		right: 5px;
		bottom: 5px;
		border-radius: inherit;
		border: 1px solid #000;
		opacity: .05;
	}
	&__text {
		font-size: 18px;
		font-weight: 700;
	}
}
