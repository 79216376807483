.benefit {
	margin-top: 100px;
	&__title {
		max-width: 688px;
		margin: 0 auto;
		text-align: center;
	}
	&__row {
		position: relative;
		margin-top: 60px;
	}
	&__tabs {
		position: absolute;
		top: 0;
		left: 0;
		width: 580px;
		max-width: 100%;
		display: flex;
		margin-left: -5px;
		margin-right: -5px;
	}
	&__tab {
		width: calc(100% / 2 - 10px);
		margin-left: 5px;
		margin-right: 5px;
		height: 72px;
		line-height: 72px;
		text-align: center;
	}
	&__item {
		display: none;
		&._active {
			display: flex;
		}
	}
	&__photos {
		margin-top: 77px;
		width: 570px;
		flex-shrink: 0;
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
	}
	&__photo {
		width: calc(100% / 3 - 4px);
		margin-top: 5px;
		overflow: hidden;
		border-radius: 2px;
		&._preview {
			width: 100%;
		}
		&-bg {
			display: block;
			width: 100%;
			padding-top: 59.5%;
			background: #000;
			background-size: cover;
			background-position: center;
			transition: .8s;
			&:hover {
				transform: scale(1.04);
			}
		}
	}
	&__info {
		margin-top: 15px;
		margin-left: 24px;
		flex-grow: 1;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
	}
	&__name {
		font-size: 20px;
		line-height: 130%;
	}
	&__desc {
		margin-top: 20px;
		max-width: 487px;
		line-height: 135%;
	}
	&__link {
		.link();
		display: inline-block;
		margin-top: 15px;
		padding-left: 19px;
		background: url(../img/zoom.png) top 2px left no-repeat;
		background-size: 15px;
		font-size: 15px;
		transition: .4s;
		display: none;
		&:hover {
			background-size: 18px;
			background-position: top left;
			padding-left: 23px;
		}
	}
	&__offer {
		position: relative;
		margin-top: 28px;
		padding: 30px 45px;
		border-radius: 2px;
		&:before {
			position: absolute;
			content: '';
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			background: linear-gradient(0deg, #FFDE8A, #FFDE8A);
			border-radius: inherit;
			opacity: .4;
			z-index: -1;
		}
		&-text {
			font-size: 20px;
			line-height: 120%;
		}
		&-mark {
			display: block;
			margin-top: 7px;
			font-size: 15px;
			font-weight: 700;
		}
	}
	&__btn {
		margin-top: auto;
		margin-bottom: 4px;
		width: 335px;
		height: 85px;
		text-align: center;
	}
}