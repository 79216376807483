.modal {
	position: relative;
	width: 630px;
	max-width: 100%;
	margin: 50px auto 0;
	padding: 50px 115px;
	border-radius: 6px;
	transform: translateX(120px);
	z-index: 2;
	&__bg {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		border-radius: inherit;
		z-index: -2;
		background: #fff;
		box-shadow: 0px 20px 55px rgba(0, 0, 0, 0.08);
		&:before {
			position: absolute;
			content: '';
			top: 5px;
			left: 5px;
			right: 5px;
			bottom: 5px;
			border-radius: inherit;
			border: 1px solid #000;
			opacity: .05;
		}
	}
	&__form {

	}
	&__laptop {
		position: absolute;
		top: -22px;
		left: -259px;
		z-index: -1;
		width: 562px;
		&-bg {
			padding-top: 82%;
			background: url(../img/laptop.png) top left no-repeat;
		}
	}
}

.modal-form {
	&__title {
		font-size: 23px;
		font-weight: 700;
	}
	&__subtitle {
		display: block;
		font-size: 17px;
	}
	&__hint {
		display: block;
		margin-top: 15px;
		font-size: 15px;
		color: #B0B0BB;
	}
	&__area {
		margin-top: 5px;
		display: flex;
		background: #fff;
		border-radius: 2px;
		border: 1px solid rgba(0,0,0,.05);
	}
	&__method {
		position: relative;
	}
	&__inputs {

	}
	&__input {
		height: 80px;
		margin-left: 140px;
		padding-left: 25px;
	}
	&__btn {
		margin: 15px auto 0;
		padding-top: 5px;
		height: 80px;
		width: 340px;
	}
	&__policy {
		display: block;
		margin: 15px auto 0;
		max-width: 226px;
		text-align: center;
	}
}

.modal {
	&-gallery {
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		z-index: 100000000;
		justify-content: center;
		align-items: center;
		display: none;
		img {
			margin: 0 auto;
			max-width: 95%;
		}
		&._active {
			display: flex;
			.modal__overlay {
				opacity: .3;
				animation: overlayFade .5s linear;
			}
			.modal__cnt {
				animation: fadeInTop .5s linear;
			}
		}
	}
	&__overlay {
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background: #000;
		opacity: 0;
		cursor: pointer;
		transition: .5s;
	}
	&__wrap {
		max-width: calc(100vw - 20px);
		position: relative;
	}
	&__content {
		text-align: center;
	}
	&__img {
		max-width: calc(100vw - 20px);
		margin: 10px auto;
		max-height: calc(100vh - 20px);
	}
	&__close {
		position: absolute !important;
		display: flex;
		align-items: center;
		justify-content: center;
		width: 30px;
		height: 30px;
		top: -28px;
		right: 0;
		z-index: 3;
		transform: rotate(45deg);
		background: transparent;
		transition: .4s transform;
		&:before, &:after {
			position: absolute;
			content: '';
			width: 20px;
			height: 2px;
			background: #DCE1E9;
		}
		&:after {
			transform-origin: center;
			transform: rotate(90deg);
		}
		&:hover {
			transform: rotate(-45deg);
		}
	}
}

@keyframes overlayFade {
	from {
		opacity: 0;
	}
	to {
		opacity: .3;
	}
}

@keyframes fadeInTop {
	from {
		transform: translateY(-30px);
	}
	to {
		transform: none
	}
}

@media screen and (max-width: 780px)
{
	.modal 
	{
		transform: none;
		padding-right: 40px;
	}
	.modal-form__input
	{
		background: none;
	}
}

@media screen and (max-width: 520px)
{
	.modal__laptop
	{
		display: none;
	}
	.modal
	{
		padding: 35px 25px;
	}
	.modal-form__input
	{
		margin-left: 110px;
	}
	.methods__label
	{
		width: 120px;
	}
	.methods__label._whatsapp:after
	{
		left: 11px
	}
	.methods__label._telegram:after
	{
		left: 17px;
	}
	.methods__label._viber:after
	{
		left: 26px;
	}
	.methods__label._email:after
	{
		left: 23px;
	}
}